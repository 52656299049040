html {
  height: 100%;
  font-size: 8px;
  line-height: 1; }

@media screen and (min-width: 1681px) {
  html {
    font-size: 10px; } }

*:focus {
  outline: 2px solid #01FF95; }

body {
  background: #262626;
  font: 400 1.6rem "CoreSans", Arial, sans-serif;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #f5f5f5; }

a {
  color: #39c1de;
  text-decoration: none; }
  a:hover {
    color: #21a7c3; }

img {
  border: 0;
  display: block; }

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
ul,
table,
hr {
  margin-top: 2rem;
  margin-bottom: 2rem; }
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  label:first-child,
  p:first-child,
  ul:first-child,
  table:first-child,
  hr:first-child {
    margin-top: 0; }
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  label:last-child,
  p:last-child,
  ul:last-child,
  table:last-child,
  hr:last-child {
    margin-bottom: 0; }

svg > * {
  fill: inherit; }

h1 > svg,
h2 > svg,
h3 > svg,
h4 > svg,
h5 > svg,
h6 > svg {
  fill: #f5f5f5;
  width: .833333em; }

h1 > *,
h2 > *,
h3 > *,
h4 > *,
h5 > *,
h6 > * {
  margin: 0 .3em; }
  h1 > *:first-child,
  h2 > *:first-child,
  h3 > *:first-child,
  h4 > *:first-child,
  h5 > *:first-child,
  h6 > *:first-child {
    margin-left: 0; }
  h1 > *:last-child,
  h2 > *:last-child,
  h3 > *:last-child,
  h4 > *:last-child,
  h5 > *:last-child,
  h6 > *:last-child {
    margin-right: 0; }

h1 {
  line-height: 1.1;
  letter-spacing: 1px;
  border-bottom: 1px solid #494949;
  padding-bottom: .5rem; }

h2 {
  line-height: 1.1;
  letter-spacing: 1px; }

h3 {
  line-height: 1.1;
  letter-spacing: 1px; }

h4 {
  line-height: 1.1;
  letter-spacing: 1px; }

h5 {
  line-height: 1.1;
  letter-spacing: 1px; }

h6 {
  line-height: 1.1;
  letter-spacing: 1px; }

label {
  display: block; }

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q,
blockquote {
  quotes: none; }

q::before,
q::after,
blockquote::before,
blockquote::after {
  content: none; }

code {
  border-radius: .3rem;
  padding: .25rem .5rem .4rem;
  background: #141414;
  color: #00cd77;
  font-family: monospace;
  display: inline-block; }
  pre code {
    padding: 1rem; }

table {
  width: 100%;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 2em; }

tr:nth-child(odd) {
  background: #1e1e1e; }

th,
td {
  border-right: 1px solid #262626;
  margin: 0;
  box-sizing: border-box;
  padding: 1.5rem 1rem;
  position: relative;
  width: auto;
  text-align: left; }
  th:last-child,
  td:last-child {
    border: 0; }

th {
  text-transform: capitalize;
  background: #373737;
  color: #f5f5f5; }

hr {
  border: 0;
  height: 1px;
  background: #494949; }

dl {
  display: block; }

dt {
  display: inline;
  margin-right: .3em;
  text-transform: uppercase;
  color: #8c8c8c; }

dd {
  display: inline;
  margin: 0;
  font-weight: 700; }
  dd::after {
    content: '\A';
    white-space: pre; }

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(2); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes buy-button-animation {
  from {
    transform: rotate(-90deg); }
  to {
    transform: rotate(270deg); } }

@font-face {
  font-family: "CoreSans";
  src: url(/static/00403064366615d9d98d4c76dfdc0a7e.eot);
  src: url(/static/8ea9d461f5d1ecc285dd917a0df184a3.woff) format("woff"), url(/static/bdb77c588c1d8b01fb2a13283725f785.ttf) format("truetype"), url(/static/3712b884e3f1f440c2f15259cb2c0ebb.svg#CoreSans) format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "CoreSans";
  src: url(/static/5a37bda4489a46104a6c2104962f7d78.eot);
  src: url(/static/38f6172671b4a5b2cf9fb4bfc2dde7c8.woff) format("woff"), url(/static/ca393b2c5c91eb583a8e9455a5101454.ttf) format("truetype"), url(/static/1864f326f8ad2fd611f5e59edc1f680f.svg#CoreSans) format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "CoreSans";
  src: url(/static/eca9b87702645d9008ddbe736450b17e.eot);
  src: url(/static/93f6d8326b8a70e04bafd7cef99da282.woff) format("woff"), url(/static/5b1c7423b6a3c71233946749112753a4.ttf) format("truetype"), url(/static/fdc68272cc9786696ac00552055dc18e.svg#CoreSans) format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "CoreSans";
  src: url(/static/3aa2fab1bb0176c17fb1ee5ac02fad01.eot);
  src: url(/static/0a3afe1b535dca98e45ec152a80446ba.woff) format("woff"), url(/static/203e9e7ba1a3109f47a768b14eda84cf.ttf) format("truetype"), url(/static/4d08e510c4c5abee0204f36c67c7f58f.svg#CoreSans) format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "CoreSans";
  src: url(/static/60527e7d9bfcbd0f25a0efe8010af7db.eot);
  src: url(/static/a5c33113fe9992ab78e280b2f061fd85.woff) format("woff"), url(/static/bdf372fba90617ec6ed073e5cb612bce.ttf) format("truetype"), url(/static/cdc2e83ffaed94de6ea8781c035bd2e3.svg#CoreSans) format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "CoreSans";
  src: url(/static/cc92db39dabf4843474a1d3f758f053f.eot);
  src: url(/static/37c5558f5811975c16f78b5b5a489086.woff) format("woff"), url(/static/8404bec6172e755785f87e22765abe67.ttf) format("truetype"), url(/static/64695b7f735d068c71be190b56014c5c.svg#CoreSans) format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/e348323f13ccb18b3b5a72c49f9357d9.eot);
  src: url(/static/a7622f60c56ddd5301549a786b54e6e6.woff) format("woff"), url(/static/73a1b98bb0336703d0801aa1c9e210e3.ttf) format("truetype"), url(/static/27ef0b062b2e221df16f3bbd97c2dca8.svg#OpenSans) format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/b3257f90f9e6bcb756a1d172525e39e2.eot);
  src: url(/static/793b1237017aeacd646fb80911425566.woff) format("woff"), url(/static/a6045fa762ae57a9e40407881fcf7ad7.ttf) format("truetype"), url(/static/c0a3320c5a9694ba4e43a880e3de5cf2.svg#OpenSans) format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/5f4d4bc11d64b6cb605b7030c1997270.eot);
  src: url(/static/de0869e324680c99efa1250515b4b41c.woff) format("woff"), url(/static/049a929c5d81988b3ae6d2f985ca7aa5.ttf) format("truetype"), url(/static/7aab4c13671282c90669eb6a10357e41.svg#OpenSans) format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/099f3e08e06bb013cd509183eb4446af.eot);
  src: url(/static/06b4bfda4e139eaf3ab9872a6d66f42f.woff) format("woff"), url(/static/a038c4d564d9c354a6c45a1da0cc414c.ttf) format("truetype"), url(/static/fd2ba7503f70aafcdcc45211a481684d.svg#OpenSans) format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/ddacaf4a9db58cda27c76ce88c408546.eot);
  src: url(/static/449d681cd6006390e1bee3c3a660430b.woff) format("woff"), url(/static/8f7128145a3c2b83923552a328df7cdc.ttf) format("truetype"), url(/static/e16f375be3c2a73b58255a02f6d3a9ce.svg#OpenSans) format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/30e948e4f8f022e4aa00fb40aec30c93.eot);
  src: url(/static/56e5756b696615d6164a625e1bcb1a9e.woff) format("woff"), url(/static/fa18d9f540e0db87b1587fe8c8b6cafc.ttf) format("truetype"), url(/static/5eb91803ad12758ca65445a23db09778.svg#OpenSans) format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/fc0dbb2edfac9ef855bcc6acd9e3477d.eot);
  src: url(/static/1f85e92d8ff443980bc0f83ad7b23b60.woff) format("woff"), url(/static/e5111caba5b811a73d995786db3c61ea.ttf) format("truetype"), url(/static/2e00b2635b51ba336b4b67a5d0bc03c7.svg#OpenSans) format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/b738a4093d8137498e5b5d1ab1d6bc25.eot);
  src: url(/static/bf72679ca22e53320beaea090e8bb07d.woff) format("woff"), url(/static/362461ffa73742ab3b1df586b87523c0.ttf) format("truetype"), url(/static/f445cf25b8e059587318c7ee9014154b.svg#OpenSans) format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/b48d8065f1875ca85f5f694a0397857e.eot);
  src: url(/static/05ebdbe10796850f045fcd484f35788d.woff) format("woff"), url(/static/67a97b77ffd3fc237ea97596d68c6763.ttf) format("truetype"), url(/static/926d4d1229e98081302daa52332d62f6.svg#OpenSans) format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "OpenSans";
  src: url(/static/26bf7113125fa325f0a0b2f9e0684595.eot);
  src: url(/static/92da6f116d973bd334cf9b3afdb29c4f.woff) format("woff"), url(/static/5d6187a1c6fa7f53b789d1f1852764ad.ttf) format("truetype"), url(/static/ca67d5b14355e769f10fd8d0f652b821.svg#OpenSans) format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: "SourceSans";
  src: url(/static/1502e2b9e8517e81183fe5b722ea1b18.eot);
  src: url(/static/9677537119ba0dca86a055691bc3d2ef.woff) format("woff"), url(/static/0b47386217456e947091d27a90480738.ttf) format("truetype"), url(/static/7af7da00d1bd582d8aaee71eb9f27fce.svg#SourceSans) format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "SourceSans";
  src: url(/static/02e6dede4dfe9b2bfd3f63a057a32ccf.eot);
  src: url(/static/4a3a99122daf1f905cb1c8945735b942.woff) format("woff"), url(/static/1b248ebd909141412f6fa8014e5226e4.ttf) format("truetype"), url(/static/17079b0a29874f0780f9c443a1168f8e.svg#SourceSans) format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "SourceSans";
  src: url(/static/a5fb4073a83be6bba05e23c5e1a7abf2.eot);
  src: url(/static/ffe92bf9691d9db678ae06e59c21ae21.woff) format("woff"), url(/static/98428458ac0710cf9e7d8c7ac978f021.ttf) format("truetype"), url(/static/aaaac5e8aa77ee7358c942279a290160.svg#SourceSans) format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Oswald";
  src: url(/static/1e43915d8e8892cd9d63cef2b2ff32eb.eot);
  src: url(/static/17cc133bc1013b5a6fb5e58b0c740fa5.woff) format("woff"), url(/static/3584c4860a93e7b659f1e982a94b1ef4.ttf) format("truetype"), url(/static/7893c1c1f815ab5ad13a8d668f1f61b8.svg#Oswald) format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url(/static/157ee0c128ac51a6464527d02dd8ce1b.eot);
  src: url(/static/fd2a9a672da0c95d1ebe6b3e5249aa83.woff) format("woff"), url(/static/fef3f1f9cc516f436432e32bb7647b7e.ttf) format("truetype"), url(/static/2db30326d4e412709a97cb2013998076.svg#Roboto) format("svg");
  font-weight: 700;
  font-style: normal; }

.no-scroll {
  overflow: hidden; }

.block-right {
  -webkit-box-flex: 1 0 auto;
  -moz-box-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  margin: 10px 15px 10px 0;
  text-align: right; }

.full-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.full-page-app {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0; }

.warning {
  color: #d42a2a; }

.validation-message {
  color: #d42a2a;
  position: absolute;
  bottom: .2rem;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0;
  transition: .1s opacity, .1s transform;
  transform: translate3d(0, -0.15rem, 0); }
  .validation-message--entered {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .validation-message--checkbox {
    bottom: -.8rem; }
  .validation-message--file-input {
    bottom: -1.5rem; }
  .validation-message--dropzone {
    bottom: -1.6rem; }

.container {
  height: 100%; }
  .container--padded {
    box-sizing: border-box;
    padding: 2rem; }
    .container--padded-horizontal {
      padding-left: 2rem;
      padding-right: 2rem; }
    .container--padded-vertical {
      padding-top: 2rem;
      padding-bottom: 2rem; }
    .container--padded-left {
      padding-left: 2rem; }
    .container--padded-right {
      padding-right: 2rem; }
    .container--padded-top {
      padding-top: 2rem; }
    .container--padded-bottom {
      padding-bottom: 2rem; }
  .container--flex {
    display: flex; }
    .container--flex-columns {
      display: flex;
      flex-direction: column; }
    .container--flex-center {
      display: flex;
      align-items: center;
      justify-content: center; }
  .container--full-size {
    width: 100%;
    height: 100%; }

.Authenticator {
  box-sizing: border-box;
  width: 29.5rem;
  transition: width .2s; }
  .Authenticator__button {
    box-sizing: border-box;
    border: 1px solid #8c8c8c;
    font-size: 2.5rem;
    display: inline-flex;
    align-items: center;
    position: relative;
    border-radius: .3rem;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    padding: 0rem 1rem;
    transition: box-shadow .1s;
    height: 5rem;
    overflow: hidden;
    width: 100%; }
    .Authenticator__button:hover {
      cursor: pointer;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  .Authenticator__animation {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 100%;
    transition: all .2s;
    height: 100%;
    padding: 0 1rem; }
    .Authenticator__animation--entering {
      transform: translateY(-10rem); }
    .Authenticator__animation--entered {
      transform: translateY(0); }
    .Authenticator__animation--exiting {
      transform: translateY(10rem); }
    .Authenticator__animation--exited {
      transform: translateY(-10rem);
      opacity: 0; }
  .Authenticator__user-avatar {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    border-radius: 50%;
    overflow: hidden; }
  .Authenticator__button-text {
    margin: 0 2rem 0 5rem;
    padding-top: .2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.5rem;
    text-transform: uppercase; }
  .Authenticator__user-text {
    margin: 0 0 0 1rem;
    padding-top: .2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.5rem;
    text-transform: uppercase; }
    .Authenticator__user-text--wrapper {
      display: flex;
      align-items: center; }
    .Authenticator__user-text .Authenticator__animation {
      padding: 0;
      display: block; }
      .Authenticator__user-text .Authenticator__animation span {
        padding: 0 1rem; }
  .Authenticator__logo-wrapper {
    overflow: visible;
    z-index: -1; }
  .Authenticator__logo {
    position: absolute;
    width: 3.5rem;
    min-width: 3.5rem;
    height: auto;
    fill: #01FF95;
    transition: all .2s;
    left: 3.5rem;
    opacity: 1;
    transform: translate(-50%, -50%); }
  .Authenticator__chevron {
    fill: #8c8c8c;
    width: 2rem;
    min-width: 2rem;
    height: auto;
    position: absolute;
    right: 1.5rem;
    padding-top: .2rem; }
  .Authenticator--logged-in {
    overflow: visible;
    width: 20rem; }
    .Authenticator--logged-in .Authenticator__button {
      overflow: visible; }
    .Authenticator--logged-in .Authenticator__dropdown {
      width: 100%;
      height: 100%;
      position: relative;
      display: block; }
      .Authenticator--logged-in .Authenticator__dropdown .Authenticator__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%; }
      .Authenticator--logged-in .Authenticator__dropdown svg {
        z-index: -1; }
      .Authenticator--logged-in .Authenticator__dropdown > div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center; }
      .Authenticator--logged-in .Authenticator__dropdown .dropdown-menu__menu {
        position: absolute;
        left: calc(-1rem - 1px);
        top: -1rem;
        border: 1px solid #8c8c8c;
        border-radius: .3rem; }
        .Authenticator--logged-in .Authenticator__dropdown .dropdown-menu__menu--open {
          top: 1rem; }
        .Authenticator--logged-in .Authenticator__dropdown .dropdown-menu__menu .menu-item {
          height: 5rem;
          box-sizing: border-box; }
    .Authenticator--logged-in .Authenticator__user-text {
      width: fit-content; }

.alert-list {
  z-index: 12;
  display: inline-block;
  position: fixed;
  width: 100%;
  max-width: 40rem;
  top: 0;
  left: 0;
  padding: 2rem 2rem 0; }
  .alert-list--right {
    left: auto;
    right: 0; }
  .alert-list--bottom {
    top: auto;
    bottom: 0; }
  .alert-list .alert {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    opacity: 0;
    max-height: 0;
    margin: 0; }
    .alert-list .alert--entered {
      max-height: 10rem;
      margin: 0 0 2rem;
      opacity: 1; }

.alert {
  z-index: 12;
  position: relative;
  border-radius: 3px;
  background: #325671;
  color: #fff;
  font-size: 1.6rem;
  overflow: hidden;
  transition: .2s transform, .2s max-height ease-out, .2s margin, .2s opacity;
  transform: scale(1);
  margin: 0 0 1rem; }
  .alert__body {
    padding: 2rem 5rem 2rem 2rem; }
  .alert__close {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    width: 1.5rem;
    padding: 1.5rem 1.5rem 0.5rem 0.5rem;
    fill: #223b4e;
    cursor: pointer; }
    .alert__close-svg {
      width: 100%; }
  .alert--success {
    background: #01FF95;
    color: #000; }
  .alert--success .alert__close {
    fill: #00b469; }
  .alert--danger {
    background: #d42a2a;
    color: #fff; }
  .alert--danger .alert__close {
    fill: #7f1919; }

.notification-badge {
  position: absolute;
  bottom: -50%;
  right: -10%;
  background: #689400;
  border-radius: 50%;
  border: none;
  color: #f1f1f2;
  height: 2.5rem;
  width: 2.5rem; }
  .notification-badge--top {
    top: -50%; }
  .notification-badge__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.button-group {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px; }
  .button-group .button {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0; }
  .button-group--full-width {
    width: 100%; }
  .button-group--no-border-radius {
    border-radius: 0; }
    .button-group--no-border-radius .button {
      border-radius: 0; }
  .button-group__button {
    margin: 0;
    display: inline-block; }
    .button-group__button--full-width {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1; }
  .button-group__button:first-child .button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .button-group__button:not(:first-child):not(:last-child) .button {
    border-radius: 0; }
  .button-group__button:last-child .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #373737;
  color: #f5f5f5;
  fill: #f5f5f5;
  border: 0 #040404 solid; }
  .button:hover {
    background: #2a2a2a;
    cursor: pointer; }
  .button:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    border-radius: .3rem; }
  .button:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.25);
    border-radius: .3rem; }
  .button svg {
    fill: #f5f5f5; }
  .button .loader__spinner-icon {
    border-top-color: #f5f5f5;
    border-bottom-color: #f5f5f5; }

.button.button--disabled,
.button.button--disabled:hover {
  background: #373737;
  cursor: not-allowed;
  opacity: .5; }

.button--darken {
  background: #1e1e1e; }
  .button--darken:hover {
    background: #111111;
    cursor: pointer; }

.button--lighten {
  background: #515151; }
  .button--lighten:hover {
    background: #444444;
    cursor: pointer; }

.button--primary {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #01FF95;
  color: #f5f5f5;
  fill: #f5f5f5;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  border: 0; }
  .button--primary:hover {
    background: #00e786;
    cursor: pointer; }
  .button--primary:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(1, 255, 149, 0.5);
    border-radius: .3rem; }
  .button--primary:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(1, 255, 149, 0.25);
    border-radius: .3rem; }
  .button--primary svg {
    fill: #f5f5f5; }
  .button--primary .loader__spinner-icon {
    border-top-color: #f5f5f5;
    border-bottom-color: #f5f5f5; }

.button--primary.button--disabled,
.button--primary.button--disabled:hover {
  background: #01FF95;
  cursor: not-allowed;
  opacity: .5; }

.button--primary--darken {
  background: #26a771; }
  .button--primary--darken:hover {
    background: #219263;
    cursor: pointer; }

.button--primary--lighten {
  background: #34ffaa; }
  .button--primary--lighten:hover {
    background: #1bffa0;
    cursor: pointer; }

.button--secondary {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #325671;
  color: #f5f5f5;
  fill: #f5f5f5;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  border: 0; }
  .button--secondary:hover {
    background: #2a495f;
    cursor: pointer; }
  .button--secondary:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(50, 86, 113, 0.5);
    border-radius: .3rem; }
  .button--secondary:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(50, 86, 113, 0.25);
    border-radius: .3rem; }
  .button--secondary svg {
    fill: #f5f5f5; }
  .button--secondary .loader__spinner-icon {
    border-top-color: #f5f5f5;
    border-bottom-color: #f5f5f5; }

.button--secondary.button--disabled,
.button--secondary.button--disabled:hover {
  background: #325671;
  cursor: not-allowed;
  opacity: .5; }

.button--secondary--darken {
  background: #373839; }
  .button--secondary--darken:hover {
    background: #2b2b2c;
    cursor: pointer; }

.button--secondary--lighten {
  background: #427194; }
  .button--secondary--lighten:hover {
    background: #3a6383;
    cursor: pointer; }

.button--hype {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #00bfff;
  color: #262626;
  fill: #262626;
  border: 0; }
  .button--hype:hover {
    background: #00ace6;
    cursor: pointer; }
  .button--hype:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(0, 191, 255, 0.5);
    border-radius: .3rem; }
  .button--hype:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(0, 191, 255, 0.25);
    border-radius: .3rem; }
  .button--hype svg {
    fill: #262626; }
  .button--hype .loader__spinner-icon {
    border-top-color: #262626;
    border-bottom-color: #262626; }

.button--hype.button--disabled,
.button--hype.button--disabled:hover {
  background: #00bfff;
  cursor: not-allowed;
  opacity: .5; }

.button--hype--darken {
  background: #2686a6; }
  .button--hype--darken:hover {
    background: #217591;
    cursor: pointer; }

.button--hype--lighten {
  background: #33ccff; }
  .button--hype--lighten:hover {
    background: #1ac5ff;
    cursor: pointer; }

.button--option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #5a5a5a;
  color: #f5f5f5;
  fill: #f5f5f5;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  border: 0; }
  .button--option:hover {
    background: #4d4d4d;
    cursor: pointer; }
  .button--option:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(90, 90, 90, 0.5);
    border-radius: .3rem; }
  .button--option:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(90, 90, 90, 0.25);
    border-radius: .3rem; }
  .button--option svg {
    fill: #f5f5f5; }
  .button--option .loader__spinner-icon {
    border-top-color: #f5f5f5;
    border-bottom-color: #f5f5f5; }

.button--option.button--disabled,
.button--option.button--disabled:hover {
  background: #5a5a5a;
  cursor: not-allowed;
  opacity: .5; }

.button--option--darken {
  background: #414141; }
  .button--option--darken:hover {
    background: #343434;
    cursor: pointer; }

.button--option--lighten {
  background: #747474; }
  .button--option--lighten:hover {
    background: #676767;
    cursor: pointer; }

.button--cart {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #ff53a0;
  color: #f5f5f5;
  fill: #f5f5f5;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  border: 0; }
  .button--cart:hover {
    background: #ff3a92;
    cursor: pointer; }
  .button--cart:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(255, 83, 160, 0.5);
    border-radius: .3rem; }
  .button--cart:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(255, 83, 160, 0.25);
    border-radius: .3rem; }
  .button--cart svg {
    fill: #f5f5f5; }
  .button--cart .loader__spinner-icon {
    border-top-color: #f5f5f5;
    border-bottom-color: #f5f5f5; }

.button--cart.button--disabled,
.button--cart.button--disabled:hover {
  background: #ff53a0;
  cursor: not-allowed;
  opacity: .5; }

.button--cart--darken {
  background: #d64988; }
  .button--cart--darken:hover {
    background: #d1347b;
    cursor: pointer; }

.button--cart--lighten {
  background: #ff86bc; }
  .button--cart--lighten:hover {
    background: #ff6dae;
    cursor: pointer; }

.button--warning {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: .3rem;
  background: #d42a2a;
  color: #f5f5f5;
  fill: #f5f5f5;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  border: 0; }
  .button--warning:hover {
    background: #bf2626;
    cursor: pointer; }
  .button--warning:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(212, 42, 42, 0.5);
    border-radius: .3rem; }
  .button--warning:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(212, 42, 42, 0.25);
    border-radius: .3rem; }
  .button--warning svg {
    fill: #f5f5f5; }
  .button--warning .loader__spinner-icon {
    border-top-color: #f5f5f5;
    border-bottom-color: #f5f5f5; }

.button--warning.button--disabled,
.button--warning.button--disabled:hover {
  background: #d42a2a;
  cursor: not-allowed;
  opacity: .5; }

.button--warning--darken {
  background: #844747; }
  .button--warning--darken:hover {
    background: #733e3e;
    cursor: pointer; }

.button--warning--lighten {
  background: #dd5454; }
  .button--warning--lighten:hover {
    background: #d93f3f;
    cursor: pointer; }

.button--outlined {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: none;
  border-radius: .3rem;
  background: #262626;
  color: #8c8c8c;
  fill: #8c8c8c;
  border: 0.1rem black solid;
  border-color: #8c8c8c; }
  .button--outlined:hover {
    background: #191919;
    cursor: pointer; }
  .button--outlined:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(140, 140, 140, 0.5);
    border-radius: .3rem; }
  .button--outlined:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(140, 140, 140, 0.25);
    border-radius: .3rem; }
  .button--outlined svg {
    fill: #8c8c8c; }
  .button--outlined .loader__spinner-icon {
    border-top-color: #8c8c8c;
    border-bottom-color: #8c8c8c; }

.button--outlined.button--disabled,
.button--outlined.button--disabled:hover {
  background: #262626;
  cursor: not-allowed;
  opacity: .5; }

.button--outlined--darken {
  background: #0d0d0d; }
  .button--outlined--darken:hover {
    background: black;
    cursor: pointer; }

.button--outlined--lighten {
  background: #404040; }
  .button--outlined--lighten:hover {
    background: #333333;
    cursor: pointer; }

.button--text {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: none;
  border-radius: .3rem;
  background: #262626;
  color: #01FF95;
  fill: #01FF95;
  border: 0 black solid; }
  .button--text:hover {
    background: #191919;
    cursor: pointer; }
  .button--text:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 rgba(1, 255, 149, 0.5);
    border-radius: .3rem; }
  .button--text:focus:after {
    transition: .3s box-shadow;
    box-shadow: 0 0 0 0.5rem rgba(1, 255, 149, 0.25);
    border-radius: .3rem; }
  .button--text svg {
    fill: #01FF95; }
  .button--text .loader__spinner-icon {
    border-top-color: #01FF95;
    border-bottom-color: #01FF95; }

.button--text.button--disabled,
.button--text.button--disabled:hover {
  background: #262626;
  cursor: not-allowed;
  opacity: .5; }

.button--text--darken {
  background: #0d0d0d; }
  .button--text--darken:hover {
    background: black;
    cursor: pointer; }

.button--text--lighten {
  background: #404040; }
  .button--text--lighten:hover {
    background: #333333;
    cursor: pointer; }

.button {
  font-size: 1.3rem;
  outline: 0;
  width: 100%;
  height: 2.6rem;
  margin: 0 0 1rem;
  padding: 0 0.5rem;
  vertical-align: bottom;
  font-family: "CoreSans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  transition: background .2s, width .2s, min-width .2s, box-shadow .2s;
  min-width: 15rem;
  cursor: pointer;
  white-space: nowrap;
  position: relative; }
  .button svg {
    height: 100%;
    width: 1.5rem;
    display: block;
    margin: auto 0; }
  .button--small {
    font-size: 1rem;
    height: 1.75rem;
    text-transform: none;
    min-width: 10rem; }
  .button--large {
    height: 5rem;
    min-width: 25rem;
    padding: 0 1.5rem; }
    .button--large svg {
      width: 2.5rem; }
  .button--medium {
    height: 3.5rem;
    padding: 0 1.5rem; }
  .button--full-width {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%; }
  .button--nomin {
    min-width: 0; }
  .button--no-margin {
    margin: 0; }
  .button__content {
    transition: color .2s;
    text-shadow: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .button__content--with-padding {
      padding: 0 0.25rem; }
    .button__content--loading {
      visibility: hidden; }
  .button:hover .button__icon {
    fill: #868686; }
  .button .loader {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.button.arrow-button {
  margin: 0 1rem; }

.arrow-button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #f5f5f5;
  width: 2.6rem;
  height: 2.6rem;
  font-size: .6rem;
  border-radius: .3rem;
  transition: background .2s;
  vertical-align: text-top;
  margin: 0 1rem 1rem;
  min-width: 2.6rem;
  cursor: pointer; }
  .arrow-button svg {
    width: 1.1rem;
    height: auto;
    fill: #f5f5f5; }
  .arrow-button:last-child {
    margin-right: 0; }
  .arrow-button:hover {
    background: #323232; }
  .arrow-button.arrow-button--disabled, .arrow-button.arrow-button--disabled:hover {
    color: rgba(245, 245, 245, 0.75);
    background: #373737; }

@media screen and (min-width: 768px) {
  .button {
    width: auto;
    margin-left: 0;
    margin-right: 0; }
    .button--full-width {
      width: 100%; } }

.drawer-toggle {
  box-sizing: border-box;
  position: relative;
  display: block; }
  .drawer-toggle--bordered {
    margin-right: 1.5rem; }
    .drawer-toggle--bordered::after {
      content: '';
      background: #fff;
      width: 0.125rem;
      height: calc(100% - 1rem * 2);
      position: absolute;
      right: -0.125rem;
      top: 0;
      bottom: 0;
      margin: auto; }
  .drawer-toggle__input {
    display: none;
    box-sizing: border-box; }
  .drawer-toggle__label {
    z-index: 100;
    display: block;
    width: 5rem;
    height: 5rem;
    padding: 1rem;
    cursor: pointer;
    margin: 0; }
  .drawer-toggle__icon {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative; }
  .drawer-toggle__dot {
    display: block;
    position: absolute;
    border: 0;
    border-radius: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background: #fff;
    box-sizing: border-box;
    transition: width 0.25s, top 0.25s, left 0.25s, transform 0.25s; }
    .drawer-toggle__dot:nth-of-type(1) {
      top: 1rem;
      left: 2.25rem; }
    .drawer-toggle__dot:nth-of-type(2) {
      top: 2.25rem;
      left: 2.25rem; }
    .drawer-toggle__dot:nth-of-type(3) {
      top: 2.25rem;
      left: 2.25rem; }
    .drawer-toggle__dot:nth-of-type(4) {
      top: 3.5rem;
      left: 2.25rem; }
  .drawer-toggle__input:checked ~ .drawer-toggle__label .drawer-toggle__dot:nth-of-type(1) {
    top: 2.25rem; }
  .drawer-toggle__input:checked ~ .drawer-toggle__label .drawer-toggle__dot:nth-of-type(2) {
    left: 1rem;
    width: 3rem;
    transform: rotate(-45deg); }
  .drawer-toggle__input:checked ~ .drawer-toggle__label .drawer-toggle__dot:nth-of-type(3) {
    left: 1rem;
    width: 3rem;
    transform: rotate(45deg); }
  .drawer-toggle__input:checked ~ .drawer-toggle__label .drawer-toggle__dot:nth-of-type(4) {
    top: 2.25rem; }

.drawer-container {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%; }
  .drawer-container--column {
    flex-direction: column; }
  .drawer-container__drawer {
    background: transparent;
    position: relative;
    box-sizing: border-box;
    flex: 0 0 0;
    overflow: hidden;
    transition: flex 0.25s;
    z-index: 7; }
    .drawer-container__drawer--overlay-left, .drawer-container__drawer--overlay-right, .drawer-container__drawer--overlay-top, .drawer-container__drawer--overlay-bottom {
      position: absolute; }
    .drawer-container__drawer--overlay-left, .drawer-container__drawer--overlay-right {
      height: 100%; }
    .drawer-container__drawer--overlay-top, .drawer-container__drawer--overlay-bottom {
      width: 100%; }
    .drawer-container__drawer--overlay-left {
      transition: left 0.25s; }
    .drawer-container__drawer--overlay-right {
      transition: right 0.25s; }
    .drawer-container__drawer--overlay-top {
      transition: top 0.25s; }
    .drawer-container__drawer--overlay-bottom {
      transition: bottom 0.25s; }
    .drawer-container__drawer-content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden auto;
      box-sizing: border-box;
      -webkit-overflow-scrolling: touch; }
      .drawer-container__drawer-content--left {
        left: auto;
        right: 0; }
  .drawer-container__body {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }

.tree {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  list-style: none;
  margin-bottom: 2rem; }
  .tree__node {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    user-select: none;
    display: flex;
    align-items: center; }
    .tree__node--selected {
      background: rgba(94, 123, 138, 0.25); }
  .tree__node-icon {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    width: 1.5rem; }
    .tree__node-icon svg {
      max-height: 1.5rem;
      width: 100%;
      height: 100%; }
  .tree__parent {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    list-style: none;
    padding-left: 2.5rem; }

.checkbox {
  margin-top: 0;
  margin-bottom: 1.5rem;
  position: relative; }
  .checkbox--no-margin {
    margin-bottom: 0; }
  .checkbox__input {
    position: absolute;
    width: .1rem;
    height: .1rem;
    overflow: hidden;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0; }
  .checkbox__label {
    line-height: 3.2rem;
    margin: 0;
    display: inline-block;
    transition: color .2s; }
    .checkbox__label::before {
      content: ' ';
      display: inline-block;
      vertical-align: baseline;
      width: 1.4rem;
      height: 1.4rem;
      border: 0.1rem solid #636363;
      margin-right: 1rem;
      margin-bottom: -.2rem;
      border-radius: .1rem;
      transition: background .1s, box-shadow .1s, border-color .1s;
      box-shadow: inset 0 0 0.1rem 0.2rem #262626;
      background: transparent;
      transition: background .2s, border-color .2s; }
    .checkbox__label--invalid::before {
      border-color: #d42a2a; }
    .checkbox__label--disabled {
      cursor: not-allowed;
      color: rgba(241, 241, 242, 0.27); }
      .checkbox__label--disabled::before {
        background: transparent;
        border-color: rgba(241, 241, 242, 0.1); }
  .checkbox--checkmark .checkbox__checkmark {
    position: absolute;
    fill: #f5f5f5;
    top: .66rem;
    left: -.175rem;
    width: 2rem;
    height: 2rem; }
  .checkbox--checkmark .checkbox__label::before {
    border-radius: .25rem;
    box-shadow: none; }

:checked + .checkbox__label::before {
  background: #01FF95; }

:checked + .checkbox__label--disabled::before {
  background: rgba(1, 255, 149, 0.25); }

:focus + .checkbox__label--default::before {
  box-shadow: inset 0 0 0.1rem 0.2rem #262626, 0 0 0.3rem 0.1rem rgba(1, 255, 149, 0.5); }

:focus + .checkbox__label--checkmark::before {
  box-shadow: 0 0 0.3rem 0.1rem rgba(1, 255, 149, 0.5); }

.date-picker {
  position: relative; }
  .date-picker:focus {
    outline: none; }
  .date-picker__label {
    line-height: 1.6rem;
    margin: 0;
    transition: color .2s;
    color: rgba(245, 245, 245, 0.3);
    font-size: 1.3rem; }
    .date-picker__label--focused {
      color: #01FF95; }
  .date-picker__input-container {
    position: relative; }
  .date-picker__icon {
    width: 2rem;
    fill: #f5f5f5;
    position: absolute;
    right: 0;
    bottom: 2.25rem;
    cursor: pointer;
    transition: color .2s, fill .2s; }
    .date-picker__icon--focused {
      fill: #01FF95; }
    .date-picker__icon--no-margin {
      bottom: .75rem; }
    .date-picker__icon--opaque {
      right: 0.5rem; }
    .date-picker__icon--invalid {
      fill: #d42a2a;
      transition: color .2s, fill .1s; }
  .date-picker__input {
    font-family: "CoreSans", Arial, sans-serif;
    font-weight: normal;
    background: none;
    outline: none;
    border: 0;
    padding: 0.5rem 0;
    margin: 0 0 1.5rem;
    border-radius: 0;
    -webkit-appearance: none;
    border-bottom: 1px solid #494949;
    transition: color .2s, border-bottom .2s;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-transform: none;
    color: #f5f5f5;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    min-height: 3.2rem;
    padding-right: 2.5rem;
    text-overflow: ellipsis; }
    .date-picker__input:placeholder-shown, .date-picker__input::placeholder {
      color: rgba(245, 245, 245, 0.3); }
    .date-picker__input:-webkit-autofill, .date-picker__input:-webkit-autofill:hover, .date-picker__input:-webkit-autofill:focus, .date-picker__input:-webkit-autofill:active {
      transition: background-color 9999s ease-in-out 0s, color 9999s ease-in-out 0s; }
    .date-picker__input:focus {
      outline: none;
      border: 0;
      border-bottom: 1px solid #01FF95; }
    .date-picker__input--no-margin {
      margin: 0; }
    .date-picker__input--opaque {
      background: #212121;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .date-picker__input--focused {
      border-bottom-color: #01FF95; }
    .date-picker__input--invalid, .date-picker__input--invalid:focus {
      border-bottom-color: #d42a2a; }
  .date-picker__fang {
    position: absolute;
    left: 1rem;
    top: calc(100% - 1rem);
    z-index: 6;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid #191919; }
    .date-picker__fang--no-margin {
      margin-top: 1.5rem; }

.date-picker-pair {
  position: relative; }
  .date-picker-pair__child {
    position: static;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    padding-right: .5rem; }
    .date-picker-pair__child:last-child {
      padding-left: .5rem;
      padding-right: 0; }

.react-calendar {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 30rem;
  position: relative;
  background: #191919; }
  .react-calendar--overlay {
    position: absolute;
    z-index: 5; }
  .react-calendar--no-margin {
    margin-top: 1.5rem; }
  .react-calendar__navigation__label, .react-calendar__navigation__arrow {
    padding: 1rem; }
    .react-calendar__navigation__label:hover, .react-calendar__navigation__label:focus, .react-calendar__navigation__arrow:hover, .react-calendar__navigation__arrow:focus {
      background: #0c0c0c; }
  .react-calendar__tile, .react-calendar__navigation__label, .react-calendar__navigation__arrow {
    border: none;
    background: transparent;
    cursor: pointer; }
    .react-calendar__tile[disabled], .react-calendar__navigation__label[disabled], .react-calendar__navigation__arrow[disabled] {
      cursor: default;
      opacity: .5;
      background: transparent; }
  .react-calendar__tile, .react-calendar__navigation__label {
    color: #f1f1f2; }
  .react-calendar__month-view__days__day, .react-calendar__year-view__months__month {
    padding: 1.2rem; }
  .react-calendar__tile:hover, .react-calendar__tile:focus, .react-calendar__tile--active {
    color: #01FF95; }
  .react-calendar__tile--active {
    background: #0c0c0c; }
  .react-calendar__navigation__arrow {
    color: #ff53a0; }
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: .5; }
  .react-calendar__month-view__weekdays__weekday {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    padding: 1rem 0;
    text-transform: uppercase; }

@media screen and (min-width: 768px) {
  .date-picker,
  .date-picker-pair {
    max-width: 30em; } }

.file-input {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-width: .1rem;
  border-style: solid;
  border-color: transparent transparent #494949;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  transition: border-color .3s;
  padding: .25rem; }
  .file-input--dropzone {
    height: auto;
    padding: 2rem;
    border: 0.2rem dashed #494949;
    justify-content: center; }
  .file-input--dragging {
    border: 0.1rem dashed #01FF95;
    transition: border-color .3s; }
  .file-input--dropzone.file-input--dragging, .file-input--dropzone.file-input--active {
    border: 0.2rem dashed #01FF95;
    transition: border-color .3s; }
  .file-input--invalid {
    border-color: transparent transparent #d42a2a; }
  .file-input--dropzone.file-input--invalid {
    border: 0.2rem dashed #d42a2a; }
  .file-input__info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    padding: 0 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .file-input--dropzone .file-input__info {
    justify-content: center;
    padding-left: 0;
    padding-right: 1rem;
    width: auto; }
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute; }
  .file-input__label {
    margin: 0;
    background: #01FF95;
    border-radius: 3px;
    box-sizing: border-box;
    color: #f5f5f5;
    cursor: pointer;
    font-family: "CoreSans", Arial, sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    outline: 0;
    padding: 0.5rem 1.5rem;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    transition: background .2s, width .2s, min-width .2s;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased; }
  .file-input__input:focus + .file-input__label, .file-input__label:hover {
    background: #00e786; }
  .file-input__cancel {
    cursor: pointer;
    padding-right: .5rem; }

@media screen and (min-width: 768px) {
  .file-input {
    max-width: 30em; } }

.search-autocomplete {
  position: relative;
  margin: 0 0 1.5rem; }
  .search-autocomplete--no-margin {
    margin: 0; }
  .search-autocomplete .validation-message {
    top: 100%; }

.dropdown-list {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  display: inline-block;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 1em;
  position: absolute;
  top: 100%;
  left: 0;
  background: #262626;
  width: 100%;
  border: 1px solid #494949;
  z-index: 5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5); }
  .dropdown-list__item {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    box-sizing: border-box;
    padding: 0.5rem;
    font-size: 1em;
    font-family: "SourceSans", Arial, sans-serif;
    border-bottom: 1px solid #494949;
    cursor: pointer;
    background: #262626;
    transition: color .2s; }
    .dropdown-list__item--active {
      background: #111111; }
    .dropdown-list__item:hover {
      background: #111111;
      transition: background .2s; }
    .dropdown-list__item:last-child {
      border-bottom: 0; }

@media screen and (min-width: 768px) {
  .search-input-form {
    max-width: 30em; } }

.select {
  box-sizing: border-box;
  min-width: 10rem;
  margin-bottom: 1.5rem; }
  .select:focus {
    outline: 0; }
  .select__label {
    line-height: 1.6rem;
    transition: color .2s;
    color: rgba(245, 245, 245, 0.3);
    font-size: 1.3rem;
    margin: 0; }
    .select__label--open, .select__label--focused {
      color: #01FF95; }
  .select--invalid div p {
    fill: #d42a2a;
    border-color: #d42a2a;
    color: #d42a2a;
    transition: color .2s, fill .1s; }
  .select--no-margin {
    margin: 0; }
  .select__container {
    width: 100%;
    position: relative; }
  .select__selected {
    position: relative;
    box-sizing: border-box;
    min-height: 3.2rem;
    line-height: 2.1rem;
    margin: 0;
    padding: 0.5rem 2rem 0.5rem 0;
    border-bottom: 1px solid #494949;
    transition: border-bottom 0.25s;
    cursor: pointer; }
    .select__selected--open, .select__selected--focused {
      border-bottom: 1px solid #01FF95; }
    .select__selected--opaque {
      padding: 0.5rem 2.5rem 0.5rem 0.5rem;
      background: #212121;
      color: #f5f5f5; }
    .select__selected--placeholder {
      color: rgba(245, 245, 245, 0.3); }
  .select__chevron-down {
    fill: #f5f5f5;
    pointer-events: all;
    transition: fill .2s, opacity .2s;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    font-size: .5em;
    transition: transform 0.25s, fill 0.25s;
    transform: rotate3d(1, 0, 0, 0deg); }
    .select__chevron-down--focused {
      fill: #01FF95; }
    .select__chevron-down--open {
      transform: rotate3d(1, 0, 0, 180deg); }
    .select__chevron-down--opaque {
      right: 0.5rem; }
    .select__chevron-down--invalid {
      color: #d42a2a; }
  .select__options-container {
    position: absolute;
    top: 100%;
    height: 0;
    overflow: hidden;
    width: 100%;
    will-change: height;
    transition: height 0.25s;
    z-index: 5;
    background: #191919; }
    .select__options-container--open {
      z-index: 3; }
    .select__options-container--size {
      position: static;
      overflow: auto;
      transition: none; }
  .select__options {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    width: 100%;
    min-width: 10rem;
    color: #f5f5f5;
    list-style-type: none;
    box-sizing: border-box;
    max-height: 28rem;
    overflow-y: auto; }
  .select__option {
    padding: 0.5rem;
    display: block;
    cursor: pointer; }
    .select__option:focus {
      outline: 0; }
    .select__option--focused {
      background: #111111; }
    .select__option--selected {
      color: #01FF95; }
    .select__option--placeholder {
      color: rgba(245, 245, 245, 0.3); }
  .select .validation-message {
    position: inherit; }

@media screen and (min-width: 768px) {
  .select {
    max-width: 30em; } }

.text-input {
  position: relative;
  margin-top: 0;
  margin-bottom: 0; }
  .text-input--with-label {
    padding-top: 1.6rem; }
  .text-input__input {
    font-family: "CoreSans", Arial, sans-serif;
    font-weight: normal;
    background: none;
    outline: none;
    border: 0;
    padding: 0.5rem 0;
    margin: 0 0 1.5rem;
    border-radius: 0;
    -webkit-appearance: none;
    border-bottom: 1px solid #494949;
    transition: color .2s, border-bottom .2s;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-transform: none;
    color: #f5f5f5;
    width: 100%;
    position: relative;
    box-sizing: border-box; }
    .text-input__input:placeholder-shown, .text-input__input::placeholder {
      color: rgba(245, 245, 245, 0.3); }
    .text-input__input:-webkit-autofill, .text-input__input:-webkit-autofill:hover, .text-input__input:-webkit-autofill:focus, .text-input__input:-webkit-autofill:active {
      transition: background-color 9999s ease-in-out 0s, color 9999s ease-in-out 0s; }
    .text-input__input:focus {
      outline: none;
      border: 0;
      border-bottom: 1px solid #01FF95; }
    .text-input__input--no-margin {
      margin: 0; }
    .text-input__input--invalid, .text-input__input--invalid:focus {
      border-bottom-color: #d42a2a; }
    .text-input__input--opaque {
      background: #212121;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .text-input__input--area {
      height: 8rem; }
    .text-input__input:-webkit-autofill + .text-input__label.text-input__label--animate {
      font-size: 1.3rem;
      transform: translate3d(0, 0, 0); }
  .text-input__label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: rgba(245, 245, 245, 0.3);
    transition: transform .2s, color .2s, font-size .2s;
    z-index: 1; }
    .text-input__label--animate {
      font-size: 1.6rem;
      transform: translate3d(0, 2.3rem, 0);
      cursor: text; }
      .text-input__label--animate.text-input__label--opaque {
        transform: translate3d(0.5rem, 2.3rem, 0); }
    .text-input__label--focused {
      color: #01FF95; }

@media screen and (min-width: 768px) {
  .text-input {
    max-width: 30em; } }

.filter-select__controls {
  display: flex;
  justify-content: center; }
  .filter-select__controls-add, .filter-select__controls-remove {
    padding: 2rem 0; }
  .filter-select__controls-add {
    padding-right: 1rem; }
  .filter-select__controls-remove {
    padding-left: 1rem; }

@media screen and (min-width: 768px) {
  .filter-select {
    display: flex; }
    .filter-select__controls {
      flex-direction: column;
      padding: 2rem; }
      .filter-select__controls-add, .filter-select__controls-remove {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0; }
      .filter-select__controls-add {
        padding: 1rem 0 2rem; } }

.icon-component {
  fill: #f5f5f5;
  pointer-events: all;
  transition: fill .2s, opacity .2s;
  width: 1.5rem;
  height: 1.5rem; }

.image-uploader {
  position: relative;
  width: 100%;
  background: #373737; }
  .image-uploader label {
    margin: 0; }
  .image-uploader__aspect-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%; }
  .image-uploader__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .image-uploader__image {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .image-uploader__canvas {
    background: #373737;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .image-uploader__canvas--active {
      z-index: 3; }
  .image-uploader__loader {
    width: 75%; }
  .image-uploader__content {
    opacity: 1;
    transition: .2s opacity;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #373737;
    overflow: hidden; }
  .image-uploader__fire {
    fill: #000;
    width: 15rem;
    margin: 1rem;
    pointer-events: none; }
    .image-uploader__fire + h3 {
      color: #000; }
  .image-uploader__content:hover .image-uploader__upload-overlay {
    opacity: 1;
    bottom: 0; }
  .image-uploader__upload-overlay {
    transition: opacity .2s, bottom .2s;
    background: #f1f1f2;
    padding: 2rem;
    text-align: center;
    z-index: 2;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.6);
    color: #f1f1f2;
    opacity: 0;
    left: 0;
    bottom: -2rem; }
  .image-uploader__dnd {
    font-size: 1.6rem;
    display: none;
    font-weight: bold;
    margin-bottom: 1.6rem; }
  .image-uploader__or {
    font-size: 1.4rem;
    display: none;
    margin-bottom: 1.6rem; }
  .image-uploader__file-input {
    display: none; }
  .image-uploader__button-group {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem;
    z-index: 4; }

@media screen and (min-width: 1150px) {
  .image-uploader__or, .image-uploader__dnd {
    display: block; } }

.layout__section {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 100%; }

.layout__main, .layout__left-col, .layout__right-col {
  -webkit-box-flex: 1 1 100%;
  -moz-box-flex: 1 1 100%;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin-bottom: 1em;
  max-width: 100%; }

.layout-title {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 1em; }
  .layout-title__text {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1 0 100%;
    -moz-box-flex: 1 0 100%;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .layout-title__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1 0 100%;
    -moz-box-flex: 1 0 100%;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
    .layout-title__item--align-right {
      -webkit-box-pack: flex-end;
      -moz-box-pack: flex-end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: flex-end;
      justify-content: flex-end; }

@media screen and (min-width: 768px) {
  .layout__section {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .layout__main {
    -webkit-box-flex: 1 1 100%;
    -moz-box-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin-right: 2em; }
    .layout__main:last-child {
      margin-right: 0; }
  .layout__left-col {
    -webkit-box-flex: 1 0 300px;
    -moz-box-flex: 1 0 300px;
    -webkit-flex: 1 0 300px;
    -ms-flex: 1 0 300px;
    flex: 1 0 300px;
    margin-right: 2em; }
    .layout__left-col:last-child {
      margin-right: 0; }
  .layout__right-col {
    -webkit-box-flex: 1 0 300px;
    -moz-box-flex: 1 0 300px;
    -webkit-flex: 1 0 300px;
    -ms-flex: 1 0 300px;
    flex: 1 0 300px;
    margin-right: 2em; }
    .layout__right-col:last-child {
      margin-right: 0; }
  .layout-title {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
    .layout-title__text {
      -webkit-box-flex: 1 1 100%;
      -moz-box-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      margin-right: 1em; }
    .layout-title__item {
      -webkit-box-flex: auto;
      -moz-box-flex: auto;
      -webkit-flex: auto;
      -ms-flex: auto;
      flex: auto;
      margin-right: 1em; }
      .layout-title__item > button {
        margin: 0; }
    .layout-title__text:last-of-type, .layout-title__item:last-of-type {
      margin-right: 0; } }

.labeled-list {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  display: inline-block; }
  .labeled-list__item {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    margin: 0 0 .7em;
    font-size: 1em;
    font-family: "SourceSans", Arial, sans-serif; }
  .labeled-list__key {
    font-size: 1em;
    margin-right: .3em;
    text-transform: uppercase;
    color: #8c8c8c; }
  .labeled-list__value {
    display: inline;
    font-weight: 700;
    color: #f5f5f5; }

.cart-list {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  font-size: 16px;
  min-width: 12rem; }
  .cart-list--max-height {
    max-height: 232px;
    overflow: hidden; }
  .cart-list__item {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem;
    font-size: .75em;
    line-height: 1.25em; }
    .cart-list__item:hover {
      background: #333;
      cursor: pointer; }
    .cart-list__item:last-child {
      margin: 0; }
  .cart-list__item-title {
    font-weight: 700;
    color: #f5f5f5;
    display: block; }
  .cart-list__item-quantity {
    color: #ff53a0;
    display: block; }
  .cart-list__item-price {
    font-weight: 400;
    color: #fff; }
  .cart-list__default-icon {
    fill: #ff53a0;
    width: 1em;
    height: 1em;
    position: relative;
    margin: 0 .5em;
    top: 1px; }
  .cart-list__holdbin-icon {
    fill: #01FF95;
    width: 1em;
    height: 1em;
    position: relative;
    margin: 0 .5em;
    top: 1px; }

.loader-wrapper {
  position: relative;
  max-height: 100%;
  height: 100%; }
  .loader-wrapper--loading {
    overflow-y: hidden; }
  .loader-wrapper__content {
    transition: .3s opacity;
    opacity: 1;
    width: 100%;
    height: 100%; }
  .loader-wrapper--loading .loader-wrapper__content {
    opacity: .2; }
  .loader-wrapper__loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 13; }

.loader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  width: 2rem;
  height: 2rem;
  opacity: 1; }
  .loader__spinner {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    transition: transform .2s, opacity .2s;
    width: 100%;
    height: 100%; }
  .loader__spinner-icon {
    border-width: .24rem;
    border-style: solid;
    border-radius: 50%;
    border-color: #6c6c6c transparent;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%; }
  .loader--large {
    width: 4rem;
    height: 4rem; }
  .loader--large .loader__spinner-icon {
    border-width: .6rem; }
  .loader--loading .loader__spinner {
    animation: fade-in .1s linear 1; }
  .loader--loading .loader__spinner-icon {
    animation: buy-button-animation 1s infinite; }
  .loader--loaded {
    opacity: 0;
    visibility: hidden; }

.progress-loader {
  position: relative;
  border-radius: .2rem;
  overflow: hidden;
  height: .4rem;
  box-sizing: border-box;
  width: 100%; }
  .progress-loader__total {
    height: .4rem;
    background: #373737;
    width: 100%;
    position: absolute; }
  .progress-loader__completed {
    height: .4rem;
    background: #01FF95;
    top: 0;
    width: 0%;
    position: relative;
    transition: all .5s; }

.dropdown-menu {
  position: relative;
  display: inline-block; }
  .dropdown-menu:focus {
    outline: none; }
  .dropdown-menu__title {
    position: relative;
    top: 0;
    left: 0;
    z-index: 4; }
  .dropdown-menu__menu {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    visibility: hidden;
    z-index: 5;
    max-height: 30rem;
    overflow: auto;
    opacity: 0;
    top: 0rem;
    transition: .2s opacity, .2s top, .2s visibility; }
    .dropdown-menu__menu--align-right {
      right: 0; }
    .dropdown-menu__menu--open {
      opacity: 1;
      visibility: visible;
      top: 3rem; }

.menu {
  min-width: 30rem;
  background: #262626; }
  .menu:focus {
    outline: 0; }

.menu-item {
  display: flex;
  align-items: center;
  padding: 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
  transition: .2s background;
  letter-spacing: 0;
  font-weight: normal; }
  .menu-item__icon {
    width: 2rem;
    height: 2rem;
    padding: 1rem;
    margin: -1rem 1rem -1rem -1rem;
    border-radius: 50%; }
  .menu-item__icon-svg {
    width: 100%;
    height: auto;
    fill: rgba(245, 245, 245, 0.5); }
  .menu-item:hover, .menu-item:active, .menu-item:focus, .menu-item--active {
    outline: 0;
    background: #373737;
    cursor: pointer; }
  .menu-item:last-of-type {
    border-bottom: 0; }

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 11;
  transition: opacity .2s;
  opacity: 0; }
  .modal--entered {
    opacity: 1; }
  .modal__content {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 95vw;
    max-height: 95vh;
    overflow: auto;
    padding: 2rem;
    background: #262626;
    color: #f5f5f5;
    box-shadow: 0 0 75px 5px #000; }
  .modal__close-button {
    fill: #f5f5f5;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2rem; }
    .modal__close-button:hover {
      fill: #a6a6a6; }

@media screen and (min-width: 768px) {
  .modal__content {
    max-width: 70rem; } }

.navigation-item {
  background: #141414;
  box-sizing: border-box;
  color: #f1f1f2;
  fill: #f1f1f2;
  display: flex;
  margin: 0;
  padding: 1.5rem 2rem;
  position: relative;
  transition: .2s color, .2s background;
  align-items: center;
  justify-content: space-between; }
  .navigation-item:hover, .navigation-item--active, .navigation-item--active:hover {
    color: #01FF95;
    fill: #01FF95;
    cursor: pointer;
    background: #262626; }
    .navigation-item:hover:before, .navigation-item--active:before, .navigation-item--active:hover:before {
      left: 0;
      content: '';
      transition: .2s left, .2s box-shadow;
      background: #01FF95;
      display: block;
      position: absolute;
      top: 0;
      width: .5rem;
      height: 100%; }
  .navigation-item--active, .navigation-item--active:hover {
    box-shadow: inset 0 0 10px 0px rgba(0, 0, 0, 0.3);
    color: #01FF95;
    background: #383838; }
    .navigation-item--active:before, .navigation-item--active:hover:before {
      left: 0; }
  .navigation-item__nested-items {
    height: 0;
    transition: height .2s;
    overflow: hidden; }
  .navigation-item__arrow-icon {
    transform: rotate(0deg);
    transition: transform .2s; }
    .navigation-item__arrow-icon--open {
      transform: rotate(90deg); }

.PaymentForm {
  background: #141414;
  max-width: 50rem;
  padding: 1em;
  padding-top: 3em;
  position: relative; }
  .PaymentForm__edit-btn {
    color: #01FF95;
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    right: 2rem;
    top: 2rem; }
  .PaymentForm__section--card {
    display: flex;
    min-height: 5rem; }
    .PaymentForm__section--card .recurly-hosted-field {
      align-items: center;
      background: #1D1D1D;
      border-radius: 2px 2px 0 0;
      display: flex;
      font-size: 10px;
      height: 4rem;
      margin: 1rem;
      padding-left: .5em;
      padding-top: 1.5rem;
      max-width: 9.75rem; }
      .PaymentForm__section--card .recurly-hosted-field-focus {
        border-bottom: 2px solid #01FF95; }
  .PaymentForm__section-label {
    color: white;
    font-family: "OpenSans", Arial, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 1rem;
    text-transform: uppercase; }
  .PaymentForm__section-fieldset {
    display: flex;
    flex-wrap: wrap; }
  .PaymentForm__group {
    position: relative;
    width: 100%; }
    .PaymentForm__group--half {
      flex-grow: 1;
      width: 50%; }
    .PaymentForm__group label {
      height: 0;
      margin: 0;
      visibility: hidden; }
    .PaymentForm__group input {
      background: #1D1D1D;
      border: none;
      border-radius: 2px 2px 0 0;
      box-sizing: border-box;
      color: white;
      display: flex;
      height: 4rem;
      margin: 1rem;
      outline: none;
      padding-bottom: 0;
      padding-left: .5em;
      padding-top: 1.5rem;
      transition: color .2s, border-bottom .2s;
      width: calc(100% - 2rem); }
      .PaymentForm__group input::placeholder {
        color: #6c6c6c; }
      .PaymentForm__group input.error {
        border-bottom: 2px solid #d42a2a;
        transition: color .2s, border-bottom .2s; }
      .PaymentForm__group input:focus {
        border-bottom: 2px solid #01FF95;
        transition: color .2s, border-bottom .2s; }
        .PaymentForm__group input:focus ~ .PaymentForm__group-label {
          color: #01FF95; }
        .PaymentForm__group input:focus ~ .PaymentForm__group-label-icon {
          opacity: 0; }
  .PaymentForm__group-select {
    background: #2e2e2e;
    border: none;
    color: white;
    height: 3rem;
    margin: 1rem;
    width: calc(100% - 2rem); }
    .PaymentForm__group-select:focus {
      outline: none !important; }
  .PaymentForm__group-message {
    color: #d42a2a;
    font-size: .9rem;
    margin: 1rem;
    margin-top: 0; }
  .PaymentForm__group-label {
    color: transparent;
    font-size: 1rem;
    font-weight: bold;
    left: 1.5rem;
    pointer-events: none;
    position: absolute;
    top: 1.5rem;
    transition: .2s color; }
    .PaymentForm__group-label--error {
      color: #d42a2a; }
    .PaymentForm__group-label--validated {
      color: #6c6c6c !important; }
  .PaymentForm__group-label-icon {
    align-items: center;
    background: none;
    display: flex;
    height: 4rem;
    position: absolute;
    right: 2rem;
    top: 1rem;
    transition: .2s opacity; }
  .PaymentForm__tc-optin {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    position: relative; }
    .PaymentForm__tc-optin label {
      margin-bottom: 0;
      margin-right: 0; }
    .PaymentForm__tc-optin a {
      margin-left: .33rem; }
  .PaymentForm__tc-optin-msg {
    color: #d42a2a;
    font-size: 1rem;
    margin-left: 1rem; }
  .PaymentForm__opt-in {
    font-size: 1.1rem;
    margin: 1rem; }
  .PaymentForm__button-text {
    display: flex; }
  .PaymentForm--static {
    padding-bottom: 6.5rem; }
    .PaymentForm--static * {
      pointer-events: none; }
    .PaymentForm--static .PaymentForm__edit-btn {
      pointer-events: all; }
    .PaymentForm--static .PaymentForm__section--card {
      height: 4rem;
      margin-top: 2rem;
      margin-bottom: 2rem; }
    .PaymentForm--static .PaymentForm__group input {
      background: #141414;
      color: #01FF95; }
      .PaymentForm--static .PaymentForm__group input::placeholder {
        color: transparent; }
    .PaymentForm--static .PaymentForm__group--card {
      color: #01FF95;
      font-size: 1.25rem;
      margin: 1.5rem 1rem 1rem 1.5rem; }
    .PaymentForm--static .PaymentForm__group-label {
      color: #6c6c6c; }
      .PaymentForm--static .PaymentForm__group-label--card {
        left: 1.5rem;
        top: 0; }
  .PaymentForm .modal__content {
    padding-right: 6rem; }
    .PaymentForm .modal__content div:first-of-type {
      font-size: 2rem; }
    .PaymentForm .modal__content div:nth-of-type(2) {
      font-size: 1.5rem;
      margin-top: 1.75rem; }
    .PaymentForm .modal__content ul {
      font-size: 1.44rem;
      color: #8c8c8c; }

@media screen and (min-width: 768px) {
  .PaymentForm__section--card .recurly-hosted-field {
    max-width: calc(17.5rem / 3); }
    .PaymentForm__section--card .recurly-hosted-field-number {
      max-width: 21.5rem; } }

.PaymentMethods {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .PaymentMethods--padded {
    padding: 3rem;
    padding-bottom: 0; }
  .PaymentMethods__method {
    align-items: center;
    background: #141414;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    outline: .25rem solid transparent;
    padding: 1rem;
    pointer-events: all;
    transition: .2s outline;
    width: 50%; }
    .PaymentMethods__method:first-of-type {
      margin-right: 2rem; }
    .PaymentMethods__method--active {
      outline: 0.25rem solid #01FF95; }
    .PaymentMethods__method img {
      height: 20px; }

.PlayerError {
  width: 100%; }
  .PlayerError__error {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.7);
    color: #bf3b3b;
    border-radius: .5rem;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center; }

.Player {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }
  .Player video {
    display: none; }
  .Player .drawer-container {
    height: auto; }
  .Player__container {
    position: relative;
    background: #000;
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center; }
  .Player .drawer-container__drawer {
    background: #141414; }
  .Player__container .track, .Player__playlist .track {
    background: #141414;
    font-size: 1rem;
    height: 4.4rem; }
  .Player__container .track__artwork, .Player__playlist .track__artwork {
    flex: 0 0 4.4rem; }
  .Player__container .track:hover, .Player__playlist .track:hover {
    background: #333333; }
  .Player__container .track--highlight, .Player__playlist .track--highlight {
    background: #2b2b2b; }
  .Player__playlist {
    background: #141414;
    overflow: auto;
    position: relative; }
  .Player__playlist-release + .Player__playlist-track {
    margin-top: 3rem; }
  .Player__playlist-clear {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #8c8c8c;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: auto;
    height: 2rem;
    width: 8.4rem;
    font-size: 1rem;
    padding-left: 3rem;
    padding-right: 1rem;
    background: linear-gradient(45deg, transparent 2rem, #000 0%);
    cursor: pointer;
    transition: .2s color;
    right: 0;
    z-index: 1; }
    .Player__playlist-clear:hover {
      color: #f5f5f5; }
  .Player__playlist-remove-track {
    fill: #8c8c8c;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: .2s fill; }
    .Player__playlist-remove-track:hover {
      fill: #f5f5f5; }
  .Player__release h4 {
    margin-bottom: 0.4rem;
    padding-bottom: 0.4rem;
    margin-top: 2rem;
    border-bottom: 1px solid #5a5a5a;
    padding-left: 4.1rem;
    color: #f1f1f2;
    font-size: 2rem; }
  .Player__error {
    padding: .4rem 1rem;
    background: rgba(0, 0, 0, 0.7);
    color: #bf3b3b;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: .5rem;
    font-weight: 600;
    font-size: 1.2rem; }
  .Player__artwork {
    flex: 0 0 5rem;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media screen and (min-width: 768px) {
      .Player__artwork {
        flex: 0 0 11rem;
        margin-right: 2.5rem; } }
    .Player__artwork-0 {
      transition: transform 0.5s, left 0.5s, width 0.5s, opacity 0.5s;
      width: 3rem;
      transform: perspective(100px) rotateY(70deg);
      position: absolute;
      left: -1rem;
      z-index: 1;
      opacity: 0;
      display: none; }
      @media screen and (min-width: 768px) {
        .Player__artwork-0 {
          display: block; } }
    .Player__artwork-1 {
      transition: transform 0.5s, left 0.5s, width 0.5s, opacity 0.5s;
      width: 4rem;
      transform: perspective(100px) rotateY(25deg);
      position: absolute;
      left: 0.5rem;
      z-index: 5;
      opacity: 0.5;
      display: none; }
      @media screen and (min-width: 768px) {
        .Player__artwork-1 {
          display: block; } }
    .Player__artwork-2 {
      transition: transform 0.5s, left 0.5s, width 0.5s, opacity 0.5s;
      width: 5rem;
      transform: perspective(100px) rotateY(0);
      z-index: 10; }
      @media screen and (min-width: 768px) {
        .Player__artwork-2 {
          position: absolute;
          left: 4rem; } }
    .Player__artwork-3 {
      transition: transform 0.5s, left 0.5s, width 0.5s, opacity 0.5s;
      width: 4rem;
      transform: perspective(100px) rotateY(-25deg);
      position: absolute;
      left: 8.5rem;
      z-index: 5;
      opacity: 0.5;
      display: none; }
      @media screen and (min-width: 768px) {
        .Player__artwork-3 {
          display: block; } }
    .Player__artwork-4 {
      transition: transform 0.5s, left 0.5s, width 0.5s, opacity 0.5s;
      width: 3rem;
      transform: perspective(100px) rotateY(-70deg);
      position: absolute;
      left: 11rem;
      z-index: 1;
      opacity: 0;
      display: none; }
      @media screen and (min-width: 568px) {
        .Player__artwork-4 {
          display: block; } }
  .Player__track-info {
    flex: 1 1 10rem;
    min-width: 10rem;
    font-size: 1.1rem;
    margin-right: 1.5rem;
    text-overflow: ellipsis;
    overflow: hidden; }
    .Player__track-info [class^="track-"] {
      color: #8c8c8c; }
    .Player__track-info [class="track-title"] {
      color: #f5f5f5; }
    .Player__track-info .track-title {
      color: #f5f5f5; }
    @media screen and (min-width: 1150px) {
      .Player__track-info {
        flex: 0 1 15rem; } }
    @media screen and (min-width: 1681px) {
      .Player__track-info {
        flex: 0 1 20rem; } }
    @media screen and (min-width: 568px) {
      .Player__track-info {
        margin-right: 2.5rem; } }
  .Player__track-info-2 {
    flex: 0 1 11.5rem;
    min-width: 11.5rem;
    margin-right: 1.5rem;
    display: none; }
    .Player__track-info-2 [class^="track-"] {
      color: #8c8c8c; }
    .Player__track-info-2 [class="track-title__primary"] {
      color: #f5f5f5; }
    @media screen and (min-width: 568px) {
      .Player__track-info-2 {
        display: flex;
        margin-right: 2.5rem; } }
  .Player__track-info-2-inner {
    width: 100%;
    display: flex;
    font-size: 1.2rem;
    flex-wrap: wrap; }
  .Player__track-bpm, .Player__track-key {
    flex: 50%;
    font-size: 1rem;
    transition: color 0.2s; }
  .Player__track-bpm--highlight .track-bpm {
    color: #01FF95; }
  .Player__track-key {
    text-align: right; }
  .Player__share {
    margin-right: 1.5rem; }
    @media screen and (min-width: 568px) {
      .Player__share {
        margin-right: 2.5rem; } }
  .Player__waveform {
    background: #000;
    flex: 3;
    height: 6rem;
    position: relative; }
    @media screen and (min-width: 1025px) {
      .Player__waveform {
        margin-right: 2.5rem;
        padding: 0; } }
  .Player__waveform-wrapper {
    height: 6rem;
    padding: .5rem 0;
    box-sizing: border-box;
    position: relative;
    transition: opacity .3s; }
    .Player__waveform-wrapper--loading {
      opacity: 0.5;
      transition: none; }
  .Player__waveform-controls {
    position: absolute;
    top: 0;
    right: 0; }
  .Player__waveform-zoom, .Player__waveform-advanced {
    box-sizing: border-box;
    width: 2.5rem;
    height: 2.5rem;
    fill: #f5f5f5;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    transition: fill .2s; }
    .Player__waveform-zoom--enabled, .Player__waveform-advanced--enabled {
      fill: #01FF95; }
  .Player__waveform-follow {
    right: 2.5rem; }
  .Player__controls {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 769px) {
      .Player__controls {
        margin-right: 1.5rem; } }
  .Player__playlist-toggle {
    margin-right: 1.5rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s, transform .2s; }
    @media screen and (min-width: 568px) {
      .Player__playlist-toggle {
        margin-left: 0;
        margin-right: 2.5rem; } }
    .Player__playlist-toggle svg {
      transition: fill 0.2s;
      fill: #bcbcc1;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer; }
      @media screen and (min-width: 568px) {
        .Player__playlist-toggle svg {
          width: 2rem;
          height: 2rem; } }
    .Player__playlist-toggle:hover svg {
      fill: #f5f5f5; }
    .Player__playlist-toggle--disabled {
      pointer-events: none;
      opacity: .3; }
    .Player__playlist-toggle--open {
      transform: rotateX(180deg); }
  .Player__extra {
    position: relative;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Player__volume {
    position: relative;
    margin-right: 1.5rem;
    height: 100%; }
    @media screen and (min-width: 568px) {
      .Player__volume {
        margin-right: 2.5rem; } }
    .Player__volume svg {
      transition: fill 0.2s;
      fill: #bcbcc1;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      display: block; }
    .Player__volume:hover svg {
      fill: #f5f5f5; }
  .Player__volume-fader {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: translate(-50%);
    height: 10rem;
    z-index: 10; }
  .Player__button {
    border-radius: 50%;
    margin-right: 0.5rem;
    width: 6rem;
    height: 6rem;
    background: #191919;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .Player__button {
        width: 3rem;
        height: 3rem;
        background: none; }
        .Player__button svg {
          width: 3rem;
          height: 3rem; } }
    .Player__button svg {
      transition: fill 0.2s;
      fill: #bcbcc1;
      width: 1.5rem;
      height: 1.5rem;
      display: block; }
    .Player__button:hover svg {
      fill: #f5f5f5; }
    .Player__button--small {
      width: 5rem;
      height: 5rem; }
      @media screen and (max-width: 768px) {
        .Player__button--small {
          width: 3rem;
          height: 3rem;
          background: none; }
          .Player__button--small svg {
            width: 3rem;
            height: 3rem; } }
      .Player__button--small svg {
        width: 1.25rem; }
    .Player__button--xs {
      width: 4rem;
      height: 4rem; }
      @media screen and (max-width: 768px) {
        .Player__button--xs {
          width: 3rem;
          height: 3rem;
          background: none; }
          .Player__button--xs svg {
            width: 3rem;
            height: 3rem; } }
      .Player__button--xs svg {
        width: 1.25rem; }
  .Player__advanced-controls {
    display: flex;
    align-items: center;
    position: relative;
    background: #000;
    height: 125px; }
  .Player__pitch-shift, .Player__zoom {
    box-sizing: border-box;
    flex: 0 0 1.5rem;
    height: 100%;
    padding: .3rem;
    margin-right: 0 .5rem; }
  .Player__realtime {
    flex: 1; }

.Clock {
  font-size: 1.2rem;
  margin-bottom: 0.25rem;
  flex: 100%;
  display: flex; }
  .Clock__played {
    display: inline-block;
    color: #39c1de;
    font-size: 1.8rem;
    font-family: monospace;
    font-weight: bold;
    letter-spacing: -0.1rem;
    flex: 1; }
  .Clock__total {
    display: inline-block;
    color: #8c8c8c;
    font-size: 1.4rem;
    font-family: monospace;
    font-weight: bold;
    letter-spacing: -0.1rem;
    align-self: flex-end; }
    .Clock__total:before {
      margin: 0 1rem;
      display: inline-block;
      content: "/"; }

.Release {
  width: 100%;
  position: relative; }
  .Release__info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
  .Release__background {
    opacity: .1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0%;
    left: 0;
    background-position-y: center;
    background-size: cover;
    pointer-events: none;
    -webkit-mask-image: linear-gradient(180deg, transparent 0%, black 100%);
    mask-image: linear-gradient(180deg, transparent 0%, black 100%);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%; }
  .Release__title {
    padding-left: 5.2rem;
    margin-bottom: 0.4rem;
    padding-bottom: 0.4rem;
    margin-top: 2rem;
    font-size: 2rem;
    flex: 1;
    font-weight: bold; }
  .Release__title a {
    color: #f1f1f2;
    transition: color .2s; }
    .Release__title a:hover {
      color: #39c1de; }
  .Release__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 75px;
    box-sizing: border-box;
    margin-right: .8rem; }

.FaderV {
  min-width: 2.5rem;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background: #333;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  border: 0.1rem #000 solid; }
  .FaderV input {
    display: none; }
  .FaderV__wrapper {
    flex: 1;
    position: relative; }
    .FaderV__wrapper:before {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 2px;
      width: 4px;
      height: 1px;
      background: rgba(245, 245, 245, 0.5);
      display: block;
      content: " "; }
    .FaderV__wrapper:after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 2px;
      width: 4px;
      height: 1px;
      background: rgba(245, 245, 245, 0.5);
      display: block;
      content: " "; }
  .FaderV__label {
    text-align: center;
    flex: 0 0 1rem;
    font-size: .8rem;
    color: #8c8c8c;
    font-weight: bold;
    text-transform: uppercase; }
  .FaderV__track {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    height: 90%;
    background: #141414;
    width: 4px;
    border-radius: 4px; }
  .FaderV__knob-position {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    box-sizing: border-box;
    height: 85%;
    width: 100%; }
  .FaderV__knob {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background-color: #373737;
    width: 125%;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 2px;
    border-top: 0.1rem solid #6c6c6c;
    border-bottom: 0.1rem solid #191919;
    box-shadow: 0 0 0 1px #000; }
    .FaderV__knob:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 1px;
      background: rgba(245, 245, 245, 0.5);
      display: block;
      content: " "; }

.FaderH {
  min-height: 3.5rem;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: #333;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem #000 solid; }
  .FaderH input {
    display: none; }
  .FaderH__wrapper {
    flex: 1;
    position: relative;
    width: 100%; }
    .FaderH__wrapper:before {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 2px;
      height: 4px;
      width: 1px;
      background: rgba(245, 245, 245, 0.5);
      display: block;
      content: " "; }
    .FaderH__wrapper:after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 2px;
      height: 4px;
      width: 1px;
      background: rgba(245, 245, 245, 0.5);
      display: block;
      content: " "; }
  .FaderH__label {
    text-align: center;
    flex: 0 0 1rem;
    font-size: .8rem;
    color: #8c8c8c;
    font-weight: bold;
    text-transform: uppercase; }
  .FaderH__track {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 90%;
    background: #141414;
    height: 4px;
    border-radius: 4px;
    overflow: hidden; }
  .FaderH__knob-position {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    box-sizing: border-box;
    height: 100%;
    width: 90%; }
  .FaderH__knob {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background-color: #373737;
    height: 125%;
    width: 1.5rem;
    cursor: pointer;
    border-radius: 2px;
    border-top: 0.1rem solid #6c6c6c;
    border-bottom: 0.1rem solid #191919;
    box-shadow: 0 0 0 1px #000; }
    .FaderH__knob:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 90%;
      width: 1px;
      background: rgba(245, 245, 245, 0.5);
      display: block;
      content: " "; }

.playlist {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #373737;
  display: flex;
  overflow: hidden;
  margin-bottom: .5rem;
  box-sizing: border-box;
  font-size: .85em;
  width: 100%; }
  .playlist--clickable {
    cursor: pointer; }
  .playlist__play-icon {
    visibility: hidden;
    fill: #f5f5f5;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; }
  .playlist__artwork {
    -webkit-box-flex: 0 0 7rem;
    -moz-box-flex: 0 0 7rem;
    -webkit-flex: 0 0 7rem;
    -ms-flex: 0 0 7rem;
    flex: 0 0 7rem;
    display: inline-block;
    position: relative;
    margin-right: 1rem; }
    .playlist__artwork--play:hover {
      cursor: pointer; }
      .playlist__artwork--play:hover::after {
        display: block;
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2); }
      .playlist__artwork--play:hover .playlist__play-icon {
        visibility: visible; }
  .playlist__col-1, .playlist__col-2, .playlist__col-3, .playlist__col-4 {
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 2rem; }
  .playlist__col-1, .playlist__col-3, .playlist__col-4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1 1 20rem;
    -moz-box-flex: 1 1 20rem;
    -webkit-flex: 1 1 20rem;
    -ms-flex: 1 1 20rem;
    flex: 1 1 20rem; }
  .playlist__col-2 {
    -webkit-box-flex: 0 1 25rem;
    -moz-box-flex: 0 1 25rem;
    -webkit-flex: 0 1 25rem;
    -ms-flex: 0 1 25rem;
    flex: 0 1 25rem;
    display: none; }
  .playlist__col-3 {
    display: none; }
  .playlist__col-4 {
    display: none; }
  .playlist__col-5 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: .8rem; }
  .playlist__title, .playlist__text, .playlist__small {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: .1em 0; }
  .playlist__title, .playlist__text {
    font-size: 1.6rem; }
  .playlist__title {
    font-weight: 700; }
    .playlist__title:hover {
      color: #01FF95; }
  .playlist__text, .playlist__small {
    font-weight: 600;
    color: #8c8c8c; }
  .playlist__small {
    font-size: 1.4rem; }

@media screen and (max-width: 1150px) {
  .playlist-tile .playlist-tile__buttonGroup {
    bottom: 0rem;
    visibility: visible; } }

@media screen and (min-width: 768px) {
  .playlist__col-2, .playlist__col-3 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; } }

@media screen and (min-width: 1024px) {
  .playlist__col-2, .playlist__col-3, .playlist__col-4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1 1 25rem;
    -moz-box-flex: 1 1 25rem;
    -webkit-flex: 1 1 25rem;
    -ms-flex: 1 1 25rem;
    flex: 1 1 25rem; } }

@media screen and (min-width: 1150px) {
  .playlist__col-3, .playlist__col-4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
  .playlist-tile:hover {
    cursor: pointer; }
    .playlist-tile:hover .playlist-tile__buttonGroup {
      bottom: 0rem;
      visibility: visible; } }

.playlist-tile {
  background: #373737;
  display: grid;
  grid-template-columns: 100%;
  height: auto;
  overflow: hidden;
  width: 100%; }
  .playlist-tile__content {
    padding: 1rem;
    max-width: 100%; }
    .playlist-tile__content > *:not(:last-child) {
      margin-bottom: 0; }
  .playlist-tile__title {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .playlist-tile__tracks {
    color: #8c8c8c;
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 2px; }
  .playlist-tile__date {
    color: #8c8c8c;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 1rem; }
  .playlist-tile__buttonGroup {
    position: absolute;
    bottom: 0rem;
    visibility: collapse;
    box-shadow: unset;
    padding: unset;
    margin: unset; }

.playlist-image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  position: relative; }
  .playlist-image img {
    width: 100%; }

.progress-bar {
  margin: 0 4rem 3rem; }
  .progress-bar__line {
    background: #fff;
    height: .27rem;
    position: relative;
    width: 100%;
    z-index: 1; }
    .progress-bar__line::before {
      background: #01FF95;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s;
      width: 0; }
    .progress-bar__line--complete::before {
      width: 100%; }
  .progress-bar__step {
    border-radius: 50%;
    border: 0.3rem solid #fff;
    height: 2rem;
    position: relative;
    transition: border-color .2s .4s;
    width: 2rem;
    z-index: 2; }
    .progress-bar__step::before {
      content: '';
      background: #01FF95;
      border-radius: 50%;
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      transition: all .2s .4s; }
    .progress-bar__step--active {
      border-color: #01FF95; }
      .progress-bar__step--active::before {
        height: 1.2rem;
        width: 1.2rem; }
    .progress-bar__step--complete {
      background: #01FF95;
      border-color: #01FF95; }
    .progress-bar__step span {
      bottom: -3rem;
      color: #fff;
      font-family: "OpenSans", Arial, sans-serif;
      font-size: 1.3rem;
      font-weight: bold;
      left: 50%;
      position: absolute;
      text-transform: uppercase;
      transform: translateX(-50%);
      white-space: nowrap; }

.slider {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-bottom: 2em;
  user-select: none; }
  .slider__title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    flex: 0 0 auto;
    font-size: 2.4rem;
    font-weight: 300;
    margin: 0 0 1rem 0;
    width: 100%; }
  .slider__item {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    position: relative;
    width: 100%;
    flex: 1;
    display: flex; }
  .slider__slide {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    transition: opacity .5s; }
    .slider__slide--visible {
      opacity: 1;
      height: auto;
      width: 100%; }
  .slider__container {
    position: relative;
    width: 100%;
    overflow: hidden; }
    .slider__container:hover .slider__arrow--left {
      left: 0;
      transition: left .2s; }
    .slider__container:hover .slider__arrow--right {
      right: 0;
      transition: right .2s; }
  .slider__arrow {
    background: url(/static/077e6adee71af4a63490c145bb5afe35.svg);
    background-size: 200% 200%;
    cursor: pointer;
    display: block;
    height: 12rem;
    position: absolute;
    top: calc(50% - 6rem);
    width: 6rem;
    z-index: 10; }
    .slider__arrow--left {
      background-position: 100% 0;
      left: -6rem;
      transition: left .2s; }
      .slider__arrow--left:hover {
        background-position: 100% 100%; }
    .slider__arrow--right {
      background-position: 0 0;
      right: -6rem;
      transition: right .2s; }
      .slider__arrow--right:hover {
        background-position: 0 100%; }
  .slider__slide-nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%; }
  .slider__slide-nav-bar {
    cursor: pointer;
    height: 5px;
    background: rgba(245, 245, 245, 0.25);
    margin-top: 1em;
    margin-right: .5em;
    width: 100%; }
    .slider__slide-nav-bar:last-child {
      margin-right: 0; }
    .slider__slide-nav-bar:hover {
      background: rgba(245, 245, 245, 0.35); }
    .slider__slide-nav-bar--current-slide {
      background: rgba(245, 245, 245, 0.65); }
      .slider__slide-nav-bar--current-slide:hover {
        background: rgba(245, 245, 245, 0.65); }

.plan-card {
  margin: 0 1rem;
  width: 28rem;
  margin-bottom: 2rem;
  background: #191919;
  color: #fff; }
  .plan-card__header {
    background: #000;
    padding: 2rem; }
  .plan-card__main {
    flex-direction: column;
    justify-content: center;
    padding: 2rem; }

.plan-feature {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff; }
  .plan-feature:not(:last-child) {
    margin-bottom: 1rem; }
  .plan-feature--upgraded {
    color: #01FF95; }
  .plan-feature svg {
    align-self: start;
    fill: #01FF95;
    flex: 0 0 auto;
    height: 2rem;
    margin-right: 1rem;
    width: auto; }

.plan-card-demo__logo {
  height: 2.3rem;
  width: auto;
  margin: 1rem auto; }
  .plan-card-demo__logo--cloud {
    display: block;
    height: 1.8rem;
    justify-self: center;
    margin-bottom: 2rem; }

.plan-card-demo__price {
  font-family: "OpenSans", Arial, sans-serif;
  font-size: 3.5rem;
  font-weight: 200; }

.plan-card-demo__taxes {
  color: #8c8c8c;
  margin-bottom: 2rem; }

.plan-card-demo__button {
  margin-bottom: 0;
  width: 100%; }

.plan-card-demo__includes {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem; }

.data-table {
  box-sizing: border-box;
  overflow: auto;
  font-size: 1.4rem;
  margin: 2rem 0; }
  .data-table:first-child {
    margin-top: 0; }
  .data-table:last-child {
    margin-bottom: 0; }
  .data-table__table {
    table-layout: fixed; }
  .data-table__th, .data-table__td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .data-table__th {
    font-size: 1.4rem;
    border-right: 1px solid #262626;
    box-sizing: border-box;
    padding: 1.5rem 1rem;
    position: relative;
    width: auto;
    text-align: left;
    text-transform: capitalize;
    background: #373737;
    color: #f5f5f5;
    top: 0;
    cursor: pointer;
    position: sticky;
    z-index: 2;
    user-select: none; }
    .data-table__th:last-child {
      border: 0; }
    .data-table__th--no-sort {
      cursor: default; }
  .data-table th {
    background: transparent; }
  .data-table .data-table__th {
    background: #373737; }
  .data-table__grip {
    height: 100%;
    position: absolute;
    width: 1rem;
    top: 0;
    right: 0;
    cursor: col-resize; }
  .data-table__sort-icon {
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    margin-left: .5rem;
    fill: #fff; }
  .data-table .checkbox__label {
    line-height: 0; }
    .data-table .checkbox__label::before {
      margin-right: 0; }

.tabs {
  width: 100%;
  margin: 1rem 0;
  box-sizing: border-box; }
  .tabs__title {
    font-size: 1.5em;
    font-weight: bold;
    text-transform: none;
    margin-bottom: .5em;
    font-family: "CoreSans", Arial, sans-serif; }
  .tabs__tabs {
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #494949;
    margin-bottom: 1em; }
  .tabs__tab {
    display: inline-block;
    padding: .2em 2em .2em 0;
    color: #8c8c8c;
    text-transform: uppercase;
    letter-spacing: .2em;
    position: relative;
    cursor: pointer;
    transition: color .2s, border-bottom 1s, width 1s;
    box-sizing: border-box; }
  .tabs__tab::after {
    display: block;
    position: absolute;
    content: ' ';
    width: calc(100% - 2em);
    border-bottom: 2px solid rgba(0, 214, 125, 0);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: border-bottom .2s; }
  .tabs__tab:hover {
    color: #f5f5f5; }
  .tabs__tab:first-child {
    padding-left: 0; }
  .tabs__tab:last-child {
    padding-right: 0; }
  .tabs__tab--active {
    color: #f5f5f5; }
  .tabs__tab--active::after {
    border-bottom: 2px solid #00D67D;
    transition: border-bottom .2s; }
  .tabs__tab--active:last-child::after {
    width: auto; }
  .tabs__results {
    margin-top: 1em; }

.release-tile,
.stem-pack-tile {
  width: 17rem;
  background: #373737;
  position: relative;
  font-size: 1.6rem;
  display: inline-block;
  vertical-align: top; }
  .release-tile__artwork,
  .stem-pack-tile__artwork {
    width: 100%;
    height: auto;
    position: relative; }
    .release-tile__artwork img,
    .stem-pack-tile__artwork img {
      width: 100%;
      height: auto;
      display: block; }
  .release-tile__title,
  .stem-pack-tile__title {
    font-weight: bold;
    font-size: .875em;
    margin: 1rem 1rem .4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer; }
  .release-tile__artists,
  .stem-pack-tile__artists {
    font-weight: bold;
    font-size: .875em;
    margin: .4rem 1rem;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    cursor: pointer;
    color: #8c8c8c; }
  .release-tile__label,
  .stem-pack-tile__label {
    font-size: .875em;
    margin: .4rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #8c8c8c;
    padding-bottom: 2rem; }
  .release-tile a,
  .stem-pack-tile a {
    cursor: pointer;
    color: inherit; }
    .release-tile a:hover,
    .stem-pack-tile a:hover {
      text-decoration: underline; }
  .release-tile__buttons,
  .stem-pack-tile__buttons {
    position: relative;
    width: 100%; }

.artist-tile {
  height: 14rem; }

.artist-tile,
.label-tile,
.supplier-tile {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  overflow: hidden;
  position: relative;
  width: 26rem; }
  .artist-tile__link,
  .label-tile__link,
  .supplier-tile__link {
    width: 100%; }
  .artist-tile__name,
  .label-tile__name,
  .supplier-tile__name {
    position: absolute;
    display: inline-block;
    bottom: .5rem;
    left: .5rem;
    padding: .5rem;
    z-index: 2;
    color: #f5f5f5; }
  .artist-tile__wrapper,
  .label-tile__wrapper,
  .supplier-tile__wrapper {
    position: relative;
    z-index: 1; }
    .artist-tile__wrapper img,
    .label-tile__wrapper img,
    .supplier-tile__wrapper img {
      width: 100%; }
  .artist-tile__overlay::after,
  .label-tile__overlay::after,
  .supplier-tile__overlay::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    content: '';
    left: 0;
    z-index: 2;
    background: linear-gradient(to top, #000 0%, transparent 50%); }

.chart-tile {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  overflow: hidden;
  position: relative;
  width: 26rem; }
  .chart-tile__link {
    width: 100%; }
  .chart-tile__name-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    padding: 1rem;
    z-index: 2; }
  .chart-tile__artist-image {
    width: 5rem;
    height: 5rem;
    border-radius: 50%; }
  .chart-tile__name {
    padding-left: 1rem;
    color: #f5f5f5; }
  .chart-tile__wrapper {
    position: relative;
    z-index: 1; }
    .chart-tile__wrapper img {
      width: 100%; }
  .chart-tile__overlay::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    content: '';
    left: 0;
    z-index: 2;
    background: linear-gradient(to top, #000 0%, transparent 50%); }

.banner {
  position: absolute;
  transform: rotate(45deg);
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: .1rem;
  color: #000;
  text-align: center;
  right: -2rem;
  top: 2rem;
  font-size: .9rem;
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  height: 0;
  width: 6.5rem;
  display: block; }
  .banner--pre-order {
    border-bottom: 1.5rem solid #ff9800; }
  .banner--exclusive {
    border-bottom: 1.5rem solid #01FF95; }
  .banner--sponsored {
    border-bottom: 1.5rem solid #39c1de; }
  .banner--guest-pick {
    border-bottom: 1.5rem solid #ff53a0; }
  .banner__text {
    position: relative;
    top: .2rem;
    font-size: .9rem;
    text-transform: uppercase; }

.tile-buttons {
  width: 100%; }

@media screen and (min-width: 1150px) {
  .release-tile__buttons,
  .stem-pack-tile__buttons {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%; }
  .release-tile:hover .release-tile__buttons,
  .stem-pack-tile:hover .release-tile__buttons, .release-tile:hover
  .stem-pack-tile__buttons,
  .stem-pack-tile:hover
  .stem-pack-tile__buttons {
    display: block; } }

.toggle {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex; }
  .toggle:first-child {
    margin-top: 0; }
  .toggle:last-child {
    margin-bottom: 0; }
  .toggle, .toggle:after, .toggle:before,
  .toggle *,
  .toggle *:after,
  .toggle *:before,
  .toggle + .toggle_button {
    box-sizing: border-box; }
    .toggle::selection, .toggle:after::selection, .toggle:before::selection,
    .toggle *::selection,
    .toggle *:after::selection,
    .toggle *:before::selection,
    .toggle + .toggle_button::selection {
      background: none; }
  .toggle:focus {
    outline: none;
    border: none; }
  .toggle__label {
    display: inline-flex; }
  .toggle__input {
    display: none; }
  .toggle__input:checked + .toggle__button:after {
    left: 50%; }
  .toggle__input:checked + .toggle__button {
    background: #00D67D; }
  .toggle--small .toggle__button {
    font-size: .8rem; }
    .toggle--small .toggle__button:after {
      transition: all 0.15s ease; }
  .toggle__button {
    font-size: 1.5rem;
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    border-radius: 2em;
    padding: 1px;
    position: relative;
    cursor: pointer;
    user-select: none;
    background: #494949;
    transition: all 0.4s ease; }
    .toggle__button:after, .toggle__button:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%; }
    .toggle__button:after {
      left: 0;
      border-radius: 50%;
      background: #262626;
      transition: all 0.2s ease; }
    .toggle__button:before {
      display: none; }
  .toggle--no-margin {
    margin: 0; }

.tooltip {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #262626;
  border: 0.1rem solid #f5f5f5;
  color: #f5f5f5;
  display: block;
  font-family: "SourceSans", Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  height: auto;
  line-height: 2.1rem;
  min-width: 100%;
  opacity: 0;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  text-align: center;
  white-space: normal;
  z-index: 10;
  transition: transform .2s, opacity .2s; }
  .tooltip::before, .tooltip::after {
    content: "";
    position: absolute; }
  .tooltip__container {
    display: inline-block;
    position: relative; }
  .tooltip--visible {
    transition-delay: .5s;
    opacity: 1; }
  .tooltip--down {
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0); }
    .tooltip--down::before, .tooltip--down::after {
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      bottom: 100%;
      left: 50%;
      margin-left: -1rem; }
    .tooltip--down::before {
      border-bottom: 1rem solid #f5f5f5;
      margin-bottom: 0px; }
    .tooltip--down::after {
      border-bottom: 1rem solid #262626;
      margin-bottom: -.1rem;
      z-index: 10; }
  .tooltip--down.tooltip--visible {
    transform: translate3d(-50%, 1rem, 0); }
  .tooltip--up {
    bottom: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0); }
    .tooltip--up::before, .tooltip--up::after {
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      top: 100%;
      left: 50%;
      margin-left: -1rem; }
    .tooltip--up::before {
      border-top: 1rem solid #f5f5f5;
      margin-top: 0px; }
    .tooltip--up::after {
      border-top: 1rem solid #262626;
      margin-top: -.1rem;
      z-index: 10; }
  .tooltip--up.tooltip--visible {
    transform: translate3d(-50%, -1rem, 0); }
  .tooltip--right {
    left: 100%;
    top: 50%;
    transform: translate3d(0, -50%, 0); }
    .tooltip--right::before, .tooltip--right::after {
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      right: 100%;
      top: 50%;
      margin-top: -1rem; }
    .tooltip--right::before {
      border-right: 1rem solid #f5f5f5;
      margin-right: 0px; }
    .tooltip--right::after {
      border-right: 1rem solid #262626;
      margin-right: -.1rem;
      z-index: 10; }
  .tooltip--right.tooltip--visible {
    transform: translate3d(1rem, -50%, 0); }
  .tooltip--left {
    right: 100%;
    top: 50%;
    transform: translate3d(0, -50%, 0); }
    .tooltip--left::before, .tooltip--left::after {
      border-top: 1rem solid transparent;
      border-bottom: 1rem solid transparent;
      left: 100%;
      top: 50%;
      margin-top: -1rem; }
    .tooltip--left::before {
      border-left: 1rem solid #f5f5f5;
      margin-left: 0px; }
    .tooltip--left::after {
      border-left: 1rem solid #262626;
      margin-left: -.1rem;
      z-index: 10; }
  .tooltip--left.tooltip--visible {
    transform: translate3d(-1rem, -50%, 0); }
  .tooltip--over {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-color: rgba(38, 38, 38, 0.75); }
  .tooltip--over.tooltip--visible {
    transform: translate3d(-50%, 0, 0); }

.tracks {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  width: 100%;
  font-size: 1.6rem;
  margin: 1rem 0; }
  .tracks--disable-hover .tracks__item:hover {
    background: none; }
  .tracks--disable-hover .tracks__item:hover .tracks__item-play-icon {
    display: none; }
  .tracks--disable-hover .tracks__item:hover .track-artwork__image {
    opacity: 1; }
  .tracks__item {
    padding-bottom: .5rem; }
    .tracks__item > .track {
      margin-bottom: 0; }
  .tracks .tooltip__container {
    display: block; }

.track {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  font-size: .85em;
  font-weight: 600;
  width: 100%;
  height: 5.5rem;
  margin-bottom: .5rem;
  background: #373737;
  border-left: rgba(1, 255, 149, 0) 0px solid;
  transition: .2s border-left, .2s padding-left, .2s background; }
  .track--small {
    height: 4.4rem; }
    .track--small .track__artwork {
      -webkit-box-flex: 0 0 4.4rem;
      -moz-box-flex: 0 0 4.4rem;
      -webkit-flex: 0 0 4.4rem;
      -ms-flex: 0 0 4.4rem;
      flex: 0 0 4.4rem; }
  .track--header {
    height: auto;
    background: transparent;
    align-items: flex-end; }
  .track--override > div > a {
    cursor: default; }
    .track--override > div > a:hover {
      color: #8c8c8c; }
  .track:hover .track-artwork__image {
    opacity: .5;
    position: relative; }
  .track:hover .track__play-icon {
    display: block; }
  .track:hover .track-title__primary, .track:hover .track-title__remixed {
    color: #01FF95; }
  .track--highlight {
    border-left: #00cd77 5px solid;
    padding-left: .2rem;
    background: #000; }
  .track--hide {
    height: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    transition: height .2s, margin .2s, opacity .2s; }
  .track__artwork {
    -webkit-box-flex: 0 0 5.5rem;
    -moz-box-flex: 0 0 5.5rem;
    -webkit-flex: 0 0 5.5rem;
    -ms-flex: 0 0 5.5rem;
    flex: 0 0 5.5rem;
    display: inline-block;
    position: relative;
    margin-right: .8rem;
    cursor: pointer; }
  .track:hover .track__artwork::after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2); }
  .track__position {
    -webkit-box-flex: 0 0 40px;
    -moz-box-flex: 0 0 40px;
    -webkit-flex: 0 0 40px;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-left: 5px;
    margin-right: .8rem;
    transition: opacity .2s;
    opacity: 1; }
  .track__item:hover {
    background: #333; }
    .track__item:hover .buy-button {
      opacity: 1; }
  .track__dragging {
    opacity: 0; }
  .track__play-icon {
    display: none;
    fill: #8c8c8c;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; }
  .track__remove-icon {
    width: 16px;
    height: 16px;
    fill: #8c8c8c;
    pointer-events: all;
    cursor: pointer; }
  .track__col--header {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .1rem; }
    .track__col--header a {
      color: #8c8c8c;
      display: flex;
      align-items: center; }
      .track__col--header a:focus {
        outline: none; }
    .track__col--header svg {
      margin-left: .5rem;
      fill: #8c8c8c; }
  .track__col-1, .track__col-2, .track__col-3, .track__col-4, .track__col-5 {
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    margin-right: .8rem; }
  .track__col-1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-grow: 2; }
  .track__col-2 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: none; }
  .track__col-3, .track__col-4, .track__col-5 {
    -webkit-box-flex: 0 1 150px;
    -moz-box-flex: 0 1 150px;
    -webkit-flex: 0 1 150px;
    -ms-flex: 0 1 150px;
    flex: 0 1 150px;
    display: none; }
  .track__col-6 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 0 0 75px;
    -moz-box-flex: 0 0 75px;
    -webkit-flex: 0 0 75px;
    -ms-flex: 0 0 75px;
    flex: 0 0 75px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: .8rem; }
  .track__col-7 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 0 0 auto;
    -moz-box-flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    margin-right: .8rem; }
  .track--disabled {
    pointer-events: none;
    opacity: .5;
    margin: 0; }
  .track--highlight {
    background: #000;
    position: relative; }
    .track--highlight::before {
      content: '';
      background: #01FF95;
      position: absolute;
      top: 0;
      left: -.8rem;
      height: 100%;
      width: .4rem; }
    .track--highlight .track-title__primary {
      color: #01FF95; }

.track-artwork {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .track-artwork__image {
    display: block;
    width: 100%;
    height: auto; }

.track-title,
.track-artists,
.track-genres,
.track-key,
.track-bpm,
.track-time,
.track-label,
.track-released {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: .1em 0; }

.track-title__primary {
  color: #fff;
  font-weight: bold; }

.track-title__remixed {
  color: #8c8c8c;
  margin-left: .5em; }

.track-artists__artist {
  color: #8c8c8c; }

.track-artists__artist:hover {
  color: #01FF95; }

.track-genres {
  color: #8c8c8c; }
  .track-genres__genre {
    color: #8c8c8c; }
  .track-genres__genre:hover {
    color: #01FF95; }

.track-key {
  color: #8c8c8c; }

.track-bpm,
.track-time,
.track-released {
  color: #8c8c8c; }

.track-label {
  color: #8c8c8c; }
  .track-label__label {
    color: #8c8c8c; }
  .track-label__label:hover {
    color: #01FF95; }

.track-position {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-size: 16px; }
  .track-position__num {
    box-sizing: border-box;
    text-align: center;
    font-size: 1em;
    width: 1em;
    color: #8c8c8c;
    font-weight: bold;
    position: relative;
    top: 1px; }

@media screen and (min-width: 768px) {
  .track__col-3, .track__col-4 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1; } }

@media screen and (min-width: 1024px) {
  .track__col-2, .track__col-3, .track__col-4, .track__col-5 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex: 1; } }

.beatport-logo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .beatport-logo__svg {
    fill: #01FF95;
    height: 2.6em;
    width: auto;
    margin-right: .4em; }
  .beatport-logo__heading {
    text-transform: uppercase;
    margin: 0;
    font-size: 1.5em;
    line-height: 1.5em;
    font-family: 'Oswald', sans-serif;
    color: #fff;
    top: .1rem;
    position: relative; }
  .beatport-logo--small {
    font-size: .5em; }
  .beatport-logo--large {
    font-size: 2em; }

.page-title {
  width: 100%; }
  .page-title__sup {
    text-transform: uppercase;
    margin: 0 0 .5rem;
    line-height: 1;
    font-size: 1.3rem;
    color: white;
    vertical-align: baseline; }
  .page-title__title {
    font-family: "Oswald", "CoreSans", Arial, sans-serif;
    text-transform: uppercase;
    display: block;
    font-size: 3.4rem;
    word-break: normal;
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: lighter; }
  .page-title__icon {
    width: .5em;
    height: .5em;
    fill: #f5f5f5;
    display: inline-block;
    margin: 0 .3em;
    vertical-align: top;
    position: relative;
    top: 2px; }

.pagination {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-bottom: 2em;
  user-select: none; }
  .pagination--no-margin {
    margin: 0; }
  .pagination__title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    flex: 0 0 auto;
    font-size: .8em;
    width: 100%; }
  .pagination__scroll-arrows {
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
  .pagination__item {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    position: relative;
    width: 100%;
    flex: 1;
    display: flex; }
  .pagination__item-transition-wrapper {
    transition: opacity .1s;
    width: 100%;
    height: 100%; }
  .pagination__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; }
    .pagination__overlay .loader {
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%); }
  .pagination__page-entry {
    display: inline-flex;
    align-items: center;
    font-size: 1.2rem;
    user-select: none; }
  .pagination__page-entry-input {
    width: auto;
    max-width: 2em;
    vertical-align: middle;
    text-align: center;
    font-size: 1.2rem;
    margin: 0 0.5rem 0 0;
    padding: 2px 0; }
  .pagination__page-total {
    letter-spacing: 1px; }

.Realtime {
  position: relative;
  display: block;
  width: 100%;
  height: 125px; }
  .Realtime canvas {
    display: block; }

.Waveform {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 150px; }
  .Waveform canvas {
    display: block; }

/**
 * Adapted from flexbox-grid-sass (https://github.com/vivid-web/flexbox-grid-sass)
 */
.grid {
  box-sizing: border-box; }

.col {
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -1rem; }

.text--left {
  text-align: left; }

.text--center {
  text-align: center; }

.text--right {
  text-align: right; }

.row--xxs-top {
  align-items: flex-start; }

.row--xxs-center {
  align-items: center; }

.row--xxs-bottom {
  align-items: flex-end; }

.col--xxs-align-top {
  align-self: flex-start; }

.col--xxs-align-center {
  align-self: center; }

.col--xxs-align-bottom {
  align-self: flex-end; }

.col--xxs-first {
  order: -1; }

.col--xxs-last {
  order: 1; }

.col--xxs-reset {
  order: 0; }

.row--xxs-reverse {
  flex-direction: row-reverse; }

.col--xxs-flex {
  display: flex; }

.text--xxs-right {
  text-align: right; }

.text--xxs-left {
  text-align: left; }

.text--xxs-center {
  text-align: center; }

.col--xxs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  margin: 0 1rem; }

.col--xxs-1 {
  width: calc((100% / 12 * 1) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-2 {
  width: calc((100% / 12 * 2) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-3 {
  width: calc((100% / 12 * 3) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-4 {
  width: calc((100% / 12 * 4) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-5 {
  width: calc((100% / 12 * 5) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-6 {
  width: calc((100% / 12 * 6) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-7 {
  width: calc((100% / 12 * 7) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-8 {
  width: calc((100% / 12 * 8) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-9 {
  width: calc((100% / 12 * 9) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-10 {
  width: calc((100% / 12 * 10) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-11 {
  width: calc((100% / 12 * 11) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-12 {
  width: calc((100% / 12 * 12) - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  flex: none; }

.col--xxs-offset-1 {
  margin-left: 8.33333%; }

.col--xxs-offset-2 {
  margin-left: 16.66667%; }

.col--xxs-offset-3 {
  margin-left: 25%; }

.col--xxs-offset-4 {
  margin-left: 33.33333%; }

.col--xxs-offset-5 {
  margin-left: 41.66667%; }

.col--xxs-offset-6 {
  margin-left: 50%; }

.col--xxs-offset-7 {
  margin-left: 58.33333%; }

.col--xxs-offset-8 {
  margin-left: 66.66667%; }

.col--xxs-offset-9 {
  margin-left: 75%; }

.col--xxs-offset-10 {
  margin-left: 83.33333%; }

.col--xxs-offset-11 {
  margin-left: 91.66667%; }

.col--xxs-offset-12 {
  margin-left: 100%; }

@media (min-width: 375px) {
  .row--xs-top {
    align-items: flex-start; }
  .row--xs-center {
    align-items: center; }
  .row--xs-bottom {
    align-items: flex-end; }
  .col--xs-align-top {
    align-self: flex-start; }
  .col--xs-align-center {
    align-self: center; }
  .col--xs-align-bottom {
    align-self: flex-end; }
  .col--xs-first {
    order: -1; }
  .col--xs-last {
    order: 1; }
  .col--xs-reset {
    order: 0; }
  .row--xs-reverse {
    flex-direction: row-reverse; }
  .col--xs-flex {
    display: flex; }
  .text--xs-right {
    text-align: right; }
  .text--xs-left {
    text-align: left; }
  .text--xs-center {
    text-align: center; }
  .col--xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    margin: 0 1rem; }
  .col--xs-1 {
    width: calc((100% / 12 * 1) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-2 {
    width: calc((100% / 12 * 2) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-3 {
    width: calc((100% / 12 * 3) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-4 {
    width: calc((100% / 12 * 4) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-5 {
    width: calc((100% / 12 * 5) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-6 {
    width: calc((100% / 12 * 6) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-7 {
    width: calc((100% / 12 * 7) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-8 {
    width: calc((100% / 12 * 8) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-9 {
    width: calc((100% / 12 * 9) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-10 {
    width: calc((100% / 12 * 10) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-11 {
    width: calc((100% / 12 * 11) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-12 {
    width: calc((100% / 12 * 12) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xs-offset-1 {
    margin-left: 8.33333%; }
  .col--xs-offset-2 {
    margin-left: 16.66667%; }
  .col--xs-offset-3 {
    margin-left: 25%; }
  .col--xs-offset-4 {
    margin-left: 33.33333%; }
  .col--xs-offset-5 {
    margin-left: 41.66667%; }
  .col--xs-offset-6 {
    margin-left: 50%; }
  .col--xs-offset-7 {
    margin-left: 58.33333%; }
  .col--xs-offset-8 {
    margin-left: 66.66667%; }
  .col--xs-offset-9 {
    margin-left: 75%; }
  .col--xs-offset-10 {
    margin-left: 83.33333%; }
  .col--xs-offset-11 {
    margin-left: 91.66667%; }
  .col--xs-offset-12 {
    margin-left: 100%; } }

@media (min-width: 568px) {
  .row--sm-top {
    align-items: flex-start; }
  .row--sm-center {
    align-items: center; }
  .row--sm-bottom {
    align-items: flex-end; }
  .col--sm-align-top {
    align-self: flex-start; }
  .col--sm-align-center {
    align-self: center; }
  .col--sm-align-bottom {
    align-self: flex-end; }
  .col--sm-first {
    order: -1; }
  .col--sm-last {
    order: 1; }
  .col--sm-reset {
    order: 0; }
  .row--sm-reverse {
    flex-direction: row-reverse; }
  .col--sm-flex {
    display: flex; }
  .text--sm-right {
    text-align: right; }
  .text--sm-left {
    text-align: left; }
  .text--sm-center {
    text-align: center; }
  .col--sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    margin: 0 1rem; }
  .col--sm-1 {
    width: calc((100% / 12 * 1) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-2 {
    width: calc((100% / 12 * 2) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-3 {
    width: calc((100% / 12 * 3) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-4 {
    width: calc((100% / 12 * 4) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-5 {
    width: calc((100% / 12 * 5) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-6 {
    width: calc((100% / 12 * 6) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-7 {
    width: calc((100% / 12 * 7) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-8 {
    width: calc((100% / 12 * 8) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-9 {
    width: calc((100% / 12 * 9) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-10 {
    width: calc((100% / 12 * 10) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-11 {
    width: calc((100% / 12 * 11) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-12 {
    width: calc((100% / 12 * 12) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--sm-offset-1 {
    margin-left: 8.33333%; }
  .col--sm-offset-2 {
    margin-left: 16.66667%; }
  .col--sm-offset-3 {
    margin-left: 25%; }
  .col--sm-offset-4 {
    margin-left: 33.33333%; }
  .col--sm-offset-5 {
    margin-left: 41.66667%; }
  .col--sm-offset-6 {
    margin-left: 50%; }
  .col--sm-offset-7 {
    margin-left: 58.33333%; }
  .col--sm-offset-8 {
    margin-left: 66.66667%; }
  .col--sm-offset-9 {
    margin-left: 75%; }
  .col--sm-offset-10 {
    margin-left: 83.33333%; }
  .col--sm-offset-11 {
    margin-left: 91.66667%; }
  .col--sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .row--md-top {
    align-items: flex-start; }
  .row--md-center {
    align-items: center; }
  .row--md-bottom {
    align-items: flex-end; }
  .col--md-align-top {
    align-self: flex-start; }
  .col--md-align-center {
    align-self: center; }
  .col--md-align-bottom {
    align-self: flex-end; }
  .col--md-first {
    order: -1; }
  .col--md-last {
    order: 1; }
  .col--md-reset {
    order: 0; }
  .row--md-reverse {
    flex-direction: row-reverse; }
  .col--md-flex {
    display: flex; }
  .text--md-right {
    text-align: right; }
  .text--md-left {
    text-align: left; }
  .text--md-center {
    text-align: center; }
  .col--md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    margin: 0 1rem; }
  .col--md-1 {
    width: calc((100% / 12 * 1) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-2 {
    width: calc((100% / 12 * 2) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-3 {
    width: calc((100% / 12 * 3) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-4 {
    width: calc((100% / 12 * 4) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-5 {
    width: calc((100% / 12 * 5) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-6 {
    width: calc((100% / 12 * 6) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-7 {
    width: calc((100% / 12 * 7) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-8 {
    width: calc((100% / 12 * 8) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-9 {
    width: calc((100% / 12 * 9) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-10 {
    width: calc((100% / 12 * 10) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-11 {
    width: calc((100% / 12 * 11) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-12 {
    width: calc((100% / 12 * 12) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--md-offset-1 {
    margin-left: 8.33333%; }
  .col--md-offset-2 {
    margin-left: 16.66667%; }
  .col--md-offset-3 {
    margin-left: 25%; }
  .col--md-offset-4 {
    margin-left: 33.33333%; }
  .col--md-offset-5 {
    margin-left: 41.66667%; }
  .col--md-offset-6 {
    margin-left: 50%; }
  .col--md-offset-7 {
    margin-left: 58.33333%; }
  .col--md-offset-8 {
    margin-left: 66.66667%; }
  .col--md-offset-9 {
    margin-left: 75%; }
  .col--md-offset-10 {
    margin-left: 83.33333%; }
  .col--md-offset-11 {
    margin-left: 91.66667%; }
  .col--md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .row--lg-top {
    align-items: flex-start; }
  .row--lg-center {
    align-items: center; }
  .row--lg-bottom {
    align-items: flex-end; }
  .col--lg-align-top {
    align-self: flex-start; }
  .col--lg-align-center {
    align-self: center; }
  .col--lg-align-bottom {
    align-self: flex-end; }
  .col--lg-first {
    order: -1; }
  .col--lg-last {
    order: 1; }
  .col--lg-reset {
    order: 0; }
  .row--lg-reverse {
    flex-direction: row-reverse; }
  .col--lg-flex {
    display: flex; }
  .text--lg-right {
    text-align: right; }
  .text--lg-left {
    text-align: left; }
  .text--lg-center {
    text-align: center; }
  .col--lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    margin: 0 1rem; }
  .col--lg-1 {
    width: calc((100% / 12 * 1) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-2 {
    width: calc((100% / 12 * 2) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-3 {
    width: calc((100% / 12 * 3) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-4 {
    width: calc((100% / 12 * 4) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-5 {
    width: calc((100% / 12 * 5) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-6 {
    width: calc((100% / 12 * 6) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-7 {
    width: calc((100% / 12 * 7) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-8 {
    width: calc((100% / 12 * 8) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-9 {
    width: calc((100% / 12 * 9) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-10 {
    width: calc((100% / 12 * 10) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-11 {
    width: calc((100% / 12 * 11) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-12 {
    width: calc((100% / 12 * 12) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--lg-offset-1 {
    margin-left: 8.33333%; }
  .col--lg-offset-2 {
    margin-left: 16.66667%; }
  .col--lg-offset-3 {
    margin-left: 25%; }
  .col--lg-offset-4 {
    margin-left: 33.33333%; }
  .col--lg-offset-5 {
    margin-left: 41.66667%; }
  .col--lg-offset-6 {
    margin-left: 50%; }
  .col--lg-offset-7 {
    margin-left: 58.33333%; }
  .col--lg-offset-8 {
    margin-left: 66.66667%; }
  .col--lg-offset-9 {
    margin-left: 75%; }
  .col--lg-offset-10 {
    margin-left: 83.33333%; }
  .col--lg-offset-11 {
    margin-left: 91.66667%; }
  .col--lg-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1150px) {
  .row--xl-top {
    align-items: flex-start; }
  .row--xl-center {
    align-items: center; }
  .row--xl-bottom {
    align-items: flex-end; }
  .col--xl-align-top {
    align-self: flex-start; }
  .col--xl-align-center {
    align-self: center; }
  .col--xl-align-bottom {
    align-self: flex-end; }
  .col--xl-first {
    order: -1; }
  .col--xl-last {
    order: 1; }
  .col--xl-reset {
    order: 0; }
  .row--xl-reverse {
    flex-direction: row-reverse; }
  .col--xl-flex {
    display: flex; }
  .text--xl-right {
    text-align: right; }
  .text--xl-left {
    text-align: left; }
  .text--xl-center {
    text-align: center; }
  .col--xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    margin: 0 1rem; }
  .col--xl-1 {
    width: calc((100% / 12 * 1) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-2 {
    width: calc((100% / 12 * 2) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-3 {
    width: calc((100% / 12 * 3) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-4 {
    width: calc((100% / 12 * 4) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-5 {
    width: calc((100% / 12 * 5) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-6 {
    width: calc((100% / 12 * 6) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-7 {
    width: calc((100% / 12 * 7) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-8 {
    width: calc((100% / 12 * 8) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-9 {
    width: calc((100% / 12 * 9) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-10 {
    width: calc((100% / 12 * 10) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-11 {
    width: calc((100% / 12 * 11) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-12 {
    width: calc((100% / 12 * 12) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xl-offset-1 {
    margin-left: 8.33333%; }
  .col--xl-offset-2 {
    margin-left: 16.66667%; }
  .col--xl-offset-3 {
    margin-left: 25%; }
  .col--xl-offset-4 {
    margin-left: 33.33333%; }
  .col--xl-offset-5 {
    margin-left: 41.66667%; }
  .col--xl-offset-6 {
    margin-left: 50%; }
  .col--xl-offset-7 {
    margin-left: 58.33333%; }
  .col--xl-offset-8 {
    margin-left: 66.66667%; }
  .col--xl-offset-9 {
    margin-left: 75%; }
  .col--xl-offset-10 {
    margin-left: 83.33333%; }
  .col--xl-offset-11 {
    margin-left: 91.66667%; }
  .col--xl-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1681px) {
  .row--xxl-top {
    align-items: flex-start; }
  .row--xxl-center {
    align-items: center; }
  .row--xxl-bottom {
    align-items: flex-end; }
  .col--xxl-align-top {
    align-self: flex-start; }
  .col--xxl-align-center {
    align-self: center; }
  .col--xxl-align-bottom {
    align-self: flex-end; }
  .col--xxl-first {
    order: -1; }
  .col--xxl-last {
    order: 1; }
  .col--xxl-reset {
    order: 0; }
  .row--xxl-reverse {
    flex-direction: row-reverse; }
  .col--xxl-flex {
    display: flex; }
  .text--xxl-right {
    text-align: right; }
  .text--xxl-left {
    text-align: left; }
  .text--xxl-center {
    text-align: center; }
  .col--xxl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    margin: 0 1rem; }
  .col--xxl-1 {
    width: calc((100% / 12 * 1) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-2 {
    width: calc((100% / 12 * 2) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-3 {
    width: calc((100% / 12 * 3) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-4 {
    width: calc((100% / 12 * 4) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-5 {
    width: calc((100% / 12 * 5) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-6 {
    width: calc((100% / 12 * 6) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-7 {
    width: calc((100% / 12 * 7) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-8 {
    width: calc((100% / 12 * 8) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-9 {
    width: calc((100% / 12 * 9) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-10 {
    width: calc((100% / 12 * 10) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-11 {
    width: calc((100% / 12 * 11) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-12 {
    width: calc((100% / 12 * 12) - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    flex: none; }
  .col--xxl-offset-1 {
    margin-left: 8.33333%; }
  .col--xxl-offset-2 {
    margin-left: 16.66667%; }
  .col--xxl-offset-3 {
    margin-left: 25%; }
  .col--xxl-offset-4 {
    margin-left: 33.33333%; }
  .col--xxl-offset-5 {
    margin-left: 41.66667%; }
  .col--xxl-offset-6 {
    margin-left: 50%; }
  .col--xxl-offset-7 {
    margin-left: 58.33333%; }
  .col--xxl-offset-8 {
    margin-left: 66.66667%; }
  .col--xxl-offset-9 {
    margin-left: 75%; }
  .col--xxl-offset-10 {
    margin-left: 83.33333%; }
  .col--xxl-offset-11 {
    margin-left: 91.66667%; }
  .col--xxl-offset-12 {
    margin-left: 100%; } }

.Thanks {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80%;
  padding: 10rem 5rem;
  text-align: center;
  width: 100%; }
  .Thanks__heading {
    border-bottom: none;
    color: #f5f5f5;
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 0;
    max-width: 50rem; }
    .Thanks__heading:last-of-type {
      font-size: 3.75rem;
      font-weight: 500;
      margin-bottom: 3rem; }
  .Thanks__message {
    margin: 2rem;
    max-width: 50rem; }
  .Thanks__btn {
    color: #00bfff;
    border-color: #00bfff; }
  .Thanks ul {
    margin-bottom: 4rem;
    padding-left: 0;
    text-align: left; }
    .Thanks ul li {
      display: flex;
      list-style: none;
      margin-bottom: 2rem; }
      .Thanks ul li .Thanks__icon {
        fill: #00bfff;
        height: 2.5rem;
        margin-right: 2rem;
        width: 2.5rem; }

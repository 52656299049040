.LabelModule {
  align-items: flex-start;
  background: #373737;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  outline: .25rem solid transparent;
  padding: 2rem;
  transition: .2s outline;
  width: 100%; }
  .LabelModule:hover {
    outline: 0.25rem solid #00bfff; }
  .LabelModule__label-info {
    align-items: center;
    display: flex; }
    .LabelModule__label-info-wrapper {
      margin-left: 2rem; }
      .LabelModule__label-info-wrapper div:first-of-type {
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: uppercase; }
        .LabelModule__label-info-wrapper div:first-of-type span {
          text-transform: capitalize; }
      .LabelModule__label-info-wrapper div:last-of-type {
        font-size: 1.25rem;
        padding-top: .25rem; }
    .LabelModule__label-info img {
      max-height: 60px;
      max-width: 60px; }
  .LabelModule .button {
    align-self: center;
    background: transparent;
    margin-top: 2rem;
    color: #a0a0a0; }

@media screen and (min-width: 768px) {
  .LabelModule {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    min-width: 60rem;
    padding: 0; }
    .LabelModule .button {
      margin: 0;
      margin-right: 2rem; } }

.PaymentMethods {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .PaymentMethods-padded {
    padding: 3rem;
    padding-bottom: 0; }
  .PaymentMethods__method {
    align-items: center;
    background: #141414;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    outline: .25rem solid transparent;
    padding: 1rem;
    transition: .2s outline;
    width: 50%; }
    .PaymentMethods__method:first-of-type {
      margin-right: 2rem; }
    .PaymentMethods__method.active {
      outline: 0.25rem solid #00bfff; }
    .PaymentMethods__method img {
      height: 20px; }

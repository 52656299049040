.RecurlyForm {
  background: #141414;
  max-width: 500px;
  padding: 1em;
  position: relative; }
  .RecurlyForm.empty {
    background: transparent; }
  .RecurlyForm__edit-btn {
    color: #00bfff;
    cursor: pointer;
    font-size: 1.25rem;
    position: absolute;
    right: 2rem;
    top: 2rem; }
  .RecurlyForm__section-label {
    color: white;
    font-family: "OpenSans", Arial, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 1rem;
    text-transform: uppercase; }
  .RecurlyForm__section-fieldset {
    display: flex;
    flex-wrap: wrap; }
  .RecurlyForm__section-card {
    display: flex;
    min-height: 5rem; }
    .RecurlyForm__section-card .recurly-hosted-field {
      align-items: center;
      background: #1D1D1D;
      border-radius: 2px 2px 0 0;
      display: flex;
      font-size: 10px;
      height: 4rem;
      margin: 1rem;
      padding-left: .5em;
      padding-top: 1.5rem;
      width: calc(($half-width - 8rem) / 3); }
      .RecurlyForm__section-card .recurly-hosted-field-focus {
        border-bottom: 2px solid #00bfff; }
      .RecurlyForm__section-card .recurly-hosted-field-number {
        width: calc(125px - 3rem); }
  .RecurlyForm__group {
    position: relative;
    width: 100%; }
    .RecurlyForm__group label {
      height: 0;
      margin: 0;
      visibility: hidden; }
    .RecurlyForm__group__half {
      flex-grow: 1;
      width: 50%; }
    .RecurlyForm__group__topmargin {
      margin-top: 1rem; }
    .RecurlyForm__group-focus-label {
      color: rgba(0, 0, 0, 0);
      font-size: 1rem;
      font-weight: bold;
      left: 1.5rem;
      pointer-events: none;
      position: absolute;
      top: 1.5rem;
      transition: .2s color; }
      .RecurlyForm__group-focus-label.error {
        color: #d42a2a; }
      .RecurlyForm__group-focus-label.validated {
        color: #6c6c6c !important; }
      .RecurlyForm__group-focus-label__icon {
        align-items: center;
        background: none;
        display: flex;
        height: 4rem;
        position: absolute;
        right: 2rem;
        top: 1rem;
        transition: .2s opacity; }
    .RecurlyForm__group input {
      background: #1D1D1D;
      border: none;
      border-radius: 2px 2px 0 0;
      box-sizing: border-box;
      color: white;
      display: flex;
      height: 4rem;
      margin: 1rem;
      outline: none;
      padding-bottom: 0;
      padding-left: .5em;
      padding-top: 1.5rem;
      transition: color .2s, border-bottom .2s;
      width: calc(100% - 2rem); }
      .RecurlyForm__group input::placeholder {
        color: #6c6c6c; }
      .RecurlyForm__group input.error {
        border-bottom: 2px solid #d42a2a;
        transition: color .2s, border-bottom .2s; }
      .RecurlyForm__group input:focus {
        border-bottom: 2px solid #00bfff;
        transition: color .2s, border-bottom .2s; }
        .RecurlyForm__group input:focus ~ .RecurlyForm__group-focus-label {
          color: #00bfff; }
          .RecurlyForm__group input:focus ~ .RecurlyForm__group-focus-label__icon {
            opacity: 0; }
    .RecurlyForm__group-select {
      background: #2e2e2e;
      border: none;
      color: white;
      height: 3rem;
      margin: 1rem;
      width: calc(100% - 2rem); }
      .RecurlyForm__group-select:focus {
        outline: none !important; }
    .RecurlyForm__group-message {
      color: #d42a2a;
      font-size: .9rem;
      margin: 1rem;
      margin-top: 0; }
    .RecurlyForm__group .static {
      margin: 1rem;
      height: 6rem; }
  .RecurlyForm__opt-in {
    font-size: 1.1rem;
    margin: 1rem; }
    .RecurlyForm__opt-in *:checked + .checkbox__label:before {
      background-color: #00bfff; }
  .RecurlyForm__button-text {
    display: flex;
    color: black; }
    .RecurlyForm__button-text .PlanPrice {
      display: flex; }
      .RecurlyForm__button-text .PlanPrice__number {
        padding: 0 .5rem; }
  .RecurlyForm.static *:not(button) {
    pointer-events: none; }
  .RecurlyForm.static *:not(.RecurlyForm__opt-in) {
    pointer-events: all; }
  .RecurlyForm.static .RecurlyForm__edit-btn {
    pointer-events: all; }
  .RecurlyForm.static .RecurlyForm__section-card {
    height: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .RecurlyForm.static .RecurlyForm__group input {
    background: #141414;
    color: #00bfff; }
  .RecurlyForm.static .RecurlyForm__group-focus-label {
    color: #6c6c6c; }
    .RecurlyForm.static .RecurlyForm__group-focus-label__card {
      left: 1.5rem;
      top: 0; }
  .RecurlyForm.static .RecurlyForm__group__card {
    color: #00bfff;
    font-size: 1.25rem;
    margin: 1.5rem 1rem 1rem 1.5rem; }
  .RecurlyForm .modal__content {
    padding-right: 6rem; }
    .RecurlyForm .modal__content div:first-of-type {
      font-size: 2rem; }
    .RecurlyForm .modal__content div:nth-of-type(2) {
      font-size: 1.5rem;
      margin-top: 1.75rem; }
    .RecurlyForm .modal__content ul {
      font-size: 1.44rem;
      color: #8c8c8c; }
  .RecurlyForm .tc-optin {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    position: relative;
    color: #a0a0a0; }
    .RecurlyForm .tc-optin__msg {
      color: #d42a2a;
      font-size: 1rem;
      margin-left: 1rem; }
    .RecurlyForm .tc-optin label {
      margin-right: 0; }
    .RecurlyForm .tc-optin a {
      margin-left: .33rem;
      color: white; }
  .RecurlyForm__opt-in {
    color: #a0a0a0; }

@media screen and (min-width: 768px) {
  .RecurlyForm__section-card .recurly-hosted-field-number {
    width: calc(250px - 3rem); } }

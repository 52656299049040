.error-boundary {
  display: flex;
  justify-content: center;
  align-items: center; }
  .error-boundary__stack-trace {
    max-width: 100%;
    overflow: hidden; }
  .error-boundary__status {
    display: block;
    color: #8c8c8c;
    font-size: 3.5rem;
    text-align: center;
    word-break: normal;
    padding: 0;
    text-align: center; }
  .error-boundary__message {
    display: block;
    font-size: 3rem;
    font-weight: 250;
    color: #8c8c8c; }
  .error-boundary__contact {
    color: #8c8c8c;
    text-align: center; }
  .error-boundary__button {
    margin: 2rem 0; }

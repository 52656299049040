.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%; }
  .App__page-wrapper {
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    padding: 10rem 2rem 10rem 2rem;
    position: relative;
    width: 100%; }

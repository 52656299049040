.Header {
  position: relative;
  align-items: center;
  background: #000;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0.75rem 2rem;
  width: 100%; }
  .Header__back {
    position: absolute;
    top: 0.5rem;
    left: 2rem;
    color: white;
    font-size: 1rem; }
  .Header a:focus {
    outline: none; }
  .Header__account {
    cursor: pointer;
    fill: white;
    height: 2rem;
    transition: .2s fill;
    width: 2rem; }
    .Header__account:hover {
      fill: #01FF95; }
    .Header__account-link {
      padding-top: .5rem;
      display: flex;
      justify-content: center; }
      .Header__account-link span {
        font-size: 1.5rem;
        padding-left: 8px;
        color: #fff;
        transition: .2s color; }
      .Header__account-link:hover .link-icon {
        fill: #01FF95; }
      .Header__account-link:hover span {
        color: #01FF95; }

.Account {
  position: relative; }
  .Account__heading {
    border-bottom: none;
    color: #f5f5f5;
    font-family: "OpenSans", Arial, sans-serif;
    font-size: 4rem;
    font-weight: normal;
    margin-bottom: 4rem;
    text-align: center;
    width: 100%; }
  .Account__billing-show-btn {
    margin-bottom: 2rem; }
  .Account__billing__payment-method {
    align-items: center;
    background: #141414;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    outline: .25rem solid transparent;
    padding: 1rem;
    transition: .2s outline;
    width: 50%; }
    .Account__billing__payment-methods {
      display: flex;
      justify-content: space-between;
      padding: 3rem;
      width: 100%; }
    .Account__billing__payment-method:first-of-type {
      margin-right: 2rem; }
    .Account__billing__payment-method.active {
      outline: 0.25rem solid #00bfff; }
    .Account__billing__payment-method.editable {
      cursor: pointer; }
    .Account__billing__payment-method img {
      height: 20px; }
  .Account .modal__content {
    background: #141414;
    width: fit-content; }
  .Account__confirm-modal {
    width: 100% !important; }
    .Account__confirm-modal-content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      font-size: 2rem; }
      .Account__confirm-modal-content .activate {
        color: #8c8c8c;
        font-size: 1.33rem;
        margin-top: 1.75rem; }
      .Account__confirm-modal-content .button {
        margin-bottom: 2rem;
        margin-top: 3rem; }

@media screen and (min-width: 768px) {
  .Account {
    min-width: 70rem; }
    .Account__heading {
      text-align: left; }
    .Account__billing-show-btn {
      margin-bottom: 0;
      position: absolute;
      right: 0;
      top: 1rem; } }

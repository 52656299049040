.Checkout__heading {
  border-bottom: none;
  color: #fff;
  font-family: "OpenSans", Arial, sans-serif;
  font-size: 4rem;
  font-weight: normal;
  margin-bottom: 4rem;
  text-align: center;
  width: 100%; }

.Checkout__payment-method {
  align-items: center;
  background: #141414;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  padding: 1rem;
  width: 50%; }
  .Checkout__payment-methods {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .Checkout__payment-method:first-of-type {
    margin-right: 2rem; }
  .Checkout__payment-method.active {
    border: 2px solid #01FF95; }
  .Checkout__payment-method img {
    height: 20px; }

.Checkout__asterisk {
  font-size: 1.25rem;
  margin-top: 1rem;
  text-align: center;
  color: #a0a0a0; }
